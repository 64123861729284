import React, { Dispatch, SetStateAction, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Button, Grid } from '@mui/material'
import {
    PracticeLocationSchedulingOperatory,
    PracticeLocationSchedulingProcedure,
    PracticeLocationSchedulingProvider,
} from 'models/v2/Practice'

import { RootState } from '../../../appReducer'
import CustomMultiselectField from '../../../modules/shared/custom-fields/CustomMultiselectField'
import { moveDefaultLocationToTop } from '../../../modules/shared/sortUtils'
import { useAppDispatch } from '../../../util/useAppDispatch'

import {
    fetchConnectedPracticeLocations,
    fetchPracticeLocationAvailabilities,
    fetchPracticeLocationSchedulingOperatories,
    fetchPracticeLocationSchedulingProcedures,
    fetchPracticeLocationSchedulingProviders,
    setSelectedLocation,
    setSelectedOperatory,
    setSelectedProcedure,
    setSelectedProvider,
} from './v2actions'
import { usePrevious } from '../../shared/custom-hooks'

import './AvailabilityFilters.sass'

type Props = {
    practiceId: string
    setLoading: Dispatch<SetStateAction<boolean>>
    dateRange: { from: string; to: string }
}

const moduleName = 'availability-filters'

const AvailabilityFilters = (props: Props) => {
    const { practiceId, setLoading, dateRange } = props

    const pstAvailabilityProcedureFilter = useSelector(
        (state: RootState) => state.app.featureFlags?.pstAvailabilityProcedureFilter,
    )
    const connectedLocations = useSelector(
        (state: RootState) => state.v2.availability.connectedPracticeLocations[practiceId] || [],
    )
    const selectedLocationId = useSelector((state: RootState) => state.v2.availability.selectedLocationId[practiceId])
    const schedulingProcedures = useSelector(
        (state: RootState) => state.v2.availability.practiceLocationSchedulingProcedures[selectedLocationId] || [],
    )
    const schedulingOperatories = useSelector(
        (state: RootState) => state.v2.availability.practiceLocationSchedulingOperatories[selectedLocationId] || [],
    )
    const schedulingProviders = useSelector(
        (state: RootState) => state.v2.availability.practiceLocationSchedulingProviders[selectedLocationId] || [],
    )
    const selectedProcedureId = useSelector((state: RootState) => state.v2.availability.selectedProcedureId[practiceId])
    const selectedOperatoryIds = useSelector(
        (state: RootState) => state.v2.availability.selectedOperatoryIds[practiceId] || [],
    )
    const selectedProviderIds = useSelector(
        (state: RootState) => state.v2.availability.selectedProviderIds[practiceId] || [],
    )

    const applyFilterButtonDisabled = !selectedLocationId || !selectedOperatoryIds.length || !selectedProviderIds.length

    const sortedConnectedLocations = connectedLocations.sort(moveDefaultLocationToTop)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchConnectedPracticeLocations(practiceId))
    }, [practiceId, dispatch])

    useEffect(() => {
        if (selectedLocationId) dispatch(fetchPracticeLocationSchedulingProcedures(selectedLocationId))
    }, [selectedLocationId, dispatch])

    useEffect(() => {
        if (selectedLocationId) dispatch(fetchPracticeLocationSchedulingOperatories(selectedLocationId))
    }, [selectedLocationId, dispatch])

    useEffect(() => {
        if (selectedLocationId) dispatch(fetchPracticeLocationSchedulingProviders(selectedLocationId))
    }, [selectedLocationId, dispatch])

    const selectPracticeLocation = (values: string[]) => {
        dispatch(setSelectedLocation(practiceId, values[0] || ''))
        dispatch(setSelectedProcedure(practiceId, ''))
        dispatch(setSelectedOperatory(practiceId, []))
        dispatch(setSelectedProvider(practiceId, []))
    }

    const selectProcedure = (values: string[]) => {
        dispatch(setSelectedProcedure(practiceId, values[0] || ''))
    }

    const selectOperatory = (operatoryIds: string[]) => {
        dispatch(setSelectedOperatory(practiceId, operatoryIds))
    }

    const selectProvider = (providerIds: string[]) => {
        dispatch(setSelectedProvider(practiceId, providerIds))
    }

    const applyFilter = useCallback(async () => {
        setLoading(true)
        await dispatch(
            fetchPracticeLocationAvailabilities(
                selectedLocationId,
                selectedProcedureId,
                selectedOperatoryIds,
                selectedProviderIds,
                dateRange,
            ),
        )
        setLoading(false)
    }, [
        dispatch,
        selectedLocationId,
        selectedProcedureId,
        selectedOperatoryIds,
        selectedProviderIds,
        dateRange,
        setLoading,
    ])

    const previousDateRange = usePrevious(dateRange)
    useEffect(() => {
        if (previousDateRange !== dateRange) {
            applyFilter()
        }
    }, [dateRange, previousDateRange, applyFilter])

    const allProceduresOption = selectedLocationId ? [{ id: 'all', name: 'ALL PROCEDURES' }] : []

    const schedulingProceduresWithPmsIdSorted = allProceduresOption.concat(
        schedulingProcedures
            .filter(p => p.enabled && !p.is_rsvp)
            .sort((a, b) => {
                const aFullName: string = a.name || ''
                return aFullName.localeCompare(b.name || '', undefined, { numeric: true, sensitivity: 'base' })
            }),
    )

    const schedulingProvidersWithPmsId = schedulingProviders.map((provider: PracticeLocationSchedulingProvider) => {
        const newProvider = { ...provider }
        if (provider.pms_id) {
            newProvider.full_name =
                newProvider.full_name !== '' ? `${newProvider.pms_id} - ${newProvider.full_name}` : newProvider.pms_id
        }

        return newProvider
    }) as PracticeLocationSchedulingProvider[]

    const schedulingProvidersWithPmsIdSorted = schedulingProvidersWithPmsId.sort((a, b) => {
        const aFullName: string = a.full_name || ''
        return aFullName.localeCompare(b.full_name || '', undefined, { numeric: true, sensitivity: 'base' })
    })

    const schedulingOperatoriesWithPmsOrNum = schedulingOperatories.map(
        (operatory: PracticeLocationSchedulingOperatory) => {
            const newOperatory = { ...operatory }
            if (newOperatory.pms_id) {
                newOperatory.name =
                    newOperatory.name !== '' ? `${newOperatory.pms_id} - ${newOperatory.name}` : newOperatory.pms_id
            }

            if (!newOperatory.pms_id && newOperatory.num) {
                newOperatory.name =
                    newOperatory.name !== '' ? `${newOperatory.num} - ${newOperatory.name}` : newOperatory.num
            }

            return newOperatory
        },
    ) as PracticeLocationSchedulingOperatory[]

    const schedulingOperatoriesWithPmsOrNumSorted = schedulingOperatoriesWithPmsOrNum.sort((a, b) => {
        return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
    })

    return (
        <div className={moduleName}>
            <Grid container={true} spacing={4} className={moduleName}>
                <Grid item={true} sm={pstAvailabilityProcedureFilter ? 3 : 4} xs={6}>
                    <CustomMultiselectField
                        items={sortedConnectedLocations}
                        maxSelected={1}
                        selectedItems={[selectedLocationId || '']}
                        keyProperty="id"
                        search={connectedLocations.length > 8}
                        placeholder="Select Practice Location"
                        label={`Select Practice Location`}
                        isRequired={true}
                        displayProperty="name"
                        onSelectElement={selectPracticeLocation}
                    />
                </Grid>
                {pstAvailabilityProcedureFilter && (
                    <Grid item={true} sm={3} xs={6}>
                        <CustomMultiselectField
                            items={schedulingProceduresWithPmsIdSorted}
                            maxSelected={1}
                            selectedItems={[selectedProcedureId || '']}
                            keyProperty="id"
                            search={schedulingProceduresWithPmsIdSorted.length > 8}
                            placeholder="Select Procedure"
                            label="Select Procedure"
                            displayProperty="name"
                            onSelectElement={selectProcedure}
                        />
                    </Grid>
                )}
                <Grid item={true} sm={pstAvailabilityProcedureFilter ? 3 : 4} xs={6}>
                    <CustomMultiselectField
                        items={schedulingOperatoriesWithPmsOrNumSorted}
                        selectAllLabel={'ALL OPERATORIES'}
                        selectedItems={selectedOperatoryIds}
                        keyProperty={'id'}
                        search={schedulingOperatoriesWithPmsOrNumSorted.length > 8}
                        placeholder={'Select Operatory'}
                        displayProperty={'name'}
                        onSelectElement={value => {
                            selectOperatory(value)
                        }}
                        label="Select Operatory"
                        isRequired={true}
                    ></CustomMultiselectField>
                </Grid>
                <Grid item={true} sm={pstAvailabilityProcedureFilter ? 3 : 4} xs={6}>
                    <CustomMultiselectField
                        items={schedulingProvidersWithPmsIdSorted}
                        selectAllLabel={'ALL PROVIDERS'}
                        selectedItems={selectedProviderIds}
                        keyProperty={'id'}
                        search={schedulingProvidersWithPmsIdSorted.length > 8}
                        placeholder={'Select Provider'}
                        displayProperty={'full_name'}
                        onSelectElement={value => {
                            selectProvider(value)
                        }}
                        label="Select Provider"
                        isRequired={true}
                    ></CustomMultiselectField>
                </Grid>
                <Grid item={true} xs={12} className={`${moduleName}__submit-button-wrapper`}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={applyFilter}
                        disabled={applyFilterButtonDisabled}
                    >
                        Check Availability
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default AvailabilityFilters
