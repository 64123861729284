import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactLoading from 'react-loading'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Icon from '@mui/material/Icon'
import classNames from 'classnames'
import throttle from 'lodash/throttle'
import moment from 'moment'

import { getSoftwareNameById } from '../../../ApiV2/mappers/v2/integrations'
import { RootState } from '../../../appReducer'
import AdminAccount from '../../../models/AdminAccount'
import { AccessAgentDashboard } from '../../../models/enums'
import { AgentType, agentTypeMap, IMappedAgentsWithLocations, IntegrationsAgent } from '../../../models/v2/Integrations'
import { useAppDispatch } from '../../../util/useAppDispatch'
import ConfirmModal from '../../shared/confirm-modal/ConfirmModal'
import CustomTooltip from '../../shared/custom-tooltip/CustomTooltip'
import InfoMessage from '../../shared/info-message/info-message'
import Paginator from '../../shared/Paginator'
import { mainBlue } from '../../shared/styles/colors'
import SuccessMessage from '../../shared/success-message/success-message'

import {
    deleteHeartbeatAgent,
    fetchPracticeLocations,
    loadAgentsPaginated,
    loadHeartbeatSoftwares,
    resetSelectedAgent,
    setAgentsErrorMessage,
} from './actions'
import { IntegrationsForm } from './IntegrationsForm'
import IntegrationsSearchBar from './IntegrationsSearchBar'

import './Integrations.sass'

const moduleName = 'integrations'

const PAGE_SIZE = 8

interface Props {
    practice: Models.Practice
    account: AdminAccount
}

export const Integrations = ({ practice, account }: Props) => {
    const agents = useSelector((state: RootState) => state.v2.integrations.heartbeat[practice.id]?.agents || [])
    const agentsLoadedAtLeastOnce = useSelector(
        (state: RootState) => state.v2.integrations.heartbeat[practice.id]?.agents,
    )
    const isLoadingAgents = useSelector((state: RootState) => state.v2.integrations.loadingAgents[practice.id] || false)
    const software = useSelector((state: RootState) => state.v2.integrations.software)
    const pagination = useSelector(
        (state: RootState) => state.v2.integrations.heartbeat[practice.id]?.pagination || null,
    )
    const query = useSelector((state: RootState) => state.v2.integrations.heartbeat[practice.id]?.query || '')
    const page = useSelector((state: RootState) => state.v2.integrations.heartbeat[practice.id]?.page || 1)
    const allPracticeLocations = useSelector((state: RootState) => state.v2.integrations.practiceLocations[practice.id])
    const allRows = useSelector(
        (state: RootState) => state.v2.integrations.heartbeat[practice.id]?.pagination?.allRows || 0,
    )
    const allPages = useSelector(
        (state: RootState) => state.v2.integrations.heartbeat[practice.id]?.pagination?.allPages || 0,
    )

    const [isModalOpened, setModalOpened] = useState(false)
    const [selectedAgent, selectAgent] = useState<IMappedAgentsWithLocations | null>(null)
    const [isSaved, setIsSaved] = useState(false)
    const [savedAgent, setSavedAgent] = useState('')
    const [integrationsQuery, setIntegrationsQuery] = useState<string>(query)
    const [searchPage, setSearchPage] = useState<number>(page)
    const [allLocationsLoaded, setAllLocationsLoaded] = useState(false)
    const [hasSearchBar, setHasSearchBar] = useState(false)
    const [firstListLoadDone, setFirstListLoadDone] = useState(false)
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

    const [deleteAgentConfirmation, setDeleteAgentConfirmation] = useState<IMappedAgentsWithLocations | undefined>(
        undefined,
    )
    const [pendingAgents, setPendingAgents] = useState<Set<string>>(new Set())
    const [loadingLocations, setLoadingLocations] = useState(false)

    const practiceId = practice.id

    const accessAgentDashboard = account.hasAccess(AccessAgentDashboard)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(loadHeartbeatSoftwares())
    }, [dispatch])

    useEffect(() => {
        if (isSaved) {
            setShowSuccessMessage(true)
            setTimeout(() => {
                setShowSuccessMessage(false)
            }, 5000)
        }
    }, [isSaved])

    const fetchAllPracticeLocations = useCallback(
        async (practiceId, page) => {
            try {
                setLoadingLocations(true)
                const response = await dispatch(fetchPracticeLocations(practiceId, page))
                if (response?.pagination_info && response.pagination_info.allPages > page) {
                    fetchAllPracticeLocations(practiceId, page + 1)
                } else {
                    setAllLocationsLoaded(true)
                    setLoadingLocations(false)
                }
            } catch (error) {
                setLoadingLocations(false)
            }
        },
        [dispatch],
    )

    useEffect(() => {
        if ((allRows > PAGE_SIZE || query !== '') && !firstListLoadDone) {
            setHasSearchBar(true)
            setFirstListLoadDone(true)
        }
    }, [allRows, firstListLoadDone, query])

    useEffect(() => {
        if (practiceId) {
            fetchAllPracticeLocations(practiceId, 1)
        }
    }, [dispatch, fetchAllPracticeLocations, practiceId])

    const searchForAgents = useCallback(
        (practiceId: string, searchQuery, searchPage: number) => {
            dispatch(loadAgentsPaginated(practiceId, searchQuery, searchPage, allPracticeLocations))
        },
        [dispatch, allPracticeLocations],
    )

    const throttledSearchAgents = useMemo(() => throttle(searchForAgents, 300), [searchForAgents])

    useEffect(() => {
        if (allLocationsLoaded && allPracticeLocations) {
            throttledSearchAgents(practiceId, integrationsQuery.trim(), searchPage)
        }
    }, [practiceId, allLocationsLoaded, integrationsQuery, allPracticeLocations, searchPage, throttledSearchAgents])

    const handleOpenModal = () => {
        setModalOpened(true)
        setIsSaved(false)
    }

    const handleCloseModal = () => {
        selectAgent(null)
        dispatch(resetSelectedAgent())
        dispatch(loadAgentsPaginated(practiceId, integrationsQuery, searchPage, allPracticeLocations))
        dispatch(setAgentsErrorMessage(practiceId, ''))
        setModalOpened(false)
    }

    const handlePageChange = (nextPage: number) => {
        setSearchPage(nextPage)
    }

    const handleRemove = (agent: IMappedAgentsWithLocations) => () => {
        setDeleteAgentConfirmation(agent)
    }

    const handleCancelRemove = () => {
        setDeleteAgentConfirmation(undefined)
    }
    const handleRemoveConfirm = async () => {
        handleCancelRemove()
        if (deleteAgentConfirmation) {
            setPendingAgents(prevState => new Set(prevState.add(deleteAgentConfirmation.id)))
            await dispatch(deleteHeartbeatAgent(practice.id, deleteAgentConfirmation.id))

            const isOnTheLastPage = searchPage === allPages
            const pageToLoad =
                searchPage > 1 && Boolean(allRows % PAGE_SIZE === 1) && isOnTheLastPage ? searchPage - 1 : searchPage
            if (searchPage !== pageToLoad) {
                setSearchPage(pageToLoad)
            } else {
                dispatch(loadAgentsPaginated(practiceId, integrationsQuery, pageToLoad, allPracticeLocations))
            }
        }
    }

    const handleSuccessMessage = (agent: IntegrationsAgent) => {
        setIsSaved(true)
        if (agent) {
            setSavedAgent(agent.name)
        }
    }

    const handleEdit = (agent: IMappedAgentsWithLocations) => () => {
        selectAgent(agent)
        handleOpenModal()
    }

    const openAgentDiagnostics = (agent: IMappedAgentsWithLocations) => () => {
        const url = `${process.env.REACT_APP_AGENT_DASHBOARD}/detail/${agent.external_agent_id}`
        window.open(url, '_blank', 'noreferrer')
    }

    const sortedAgents: IMappedAgentsWithLocations[] = useMemo(
        () => agents.sort((a, b) => a.name.localeCompare(b.name)),
        [agents],
    )

    const noRecordsFound =
        !loadingLocations && query === '' && !isLoadingAgents && agentsLoadedAtLeastOnce && sortedAgents.length === 0

    return (
        <>
            <div className={classNames('integrations-tab', moduleName)}>
                <CSSTransition
                    in={showSuccessMessage}
                    mountOnEnter={true}
                    unmountOnExit={true}
                    timeout={{ enter: 500, exit: 500 }}
                    className={classNames(`integrations-updated-successfully`)}
                >
                    <SuccessMessage isShown={isSaved}>
                        {savedAgent ? `Agent "${savedAgent}"` : 'An agent'} has been saved.
                    </SuccessMessage>
                </CSSTransition>
                {hasSearchBar && (
                    <div className={`integrations-search-bar-wrapper`}>
                        <IntegrationsSearchBar
                            practiceId={practiceId}
                            setIntegrationsQuery={setIntegrationsQuery}
                            setSearchPage={setSearchPage}
                            integrationsQuery={integrationsQuery}
                        />
                    </div>
                )}
                {noRecordsFound && <InfoMessage isShown={true}>No records found for selected criteria</InfoMessage>}
                <div className="card-container">
                    {sortedAgents.length > 0 ? (
                        sortedAgents.map(agent => (
                            <Card
                                key={agent.id}
                                className={`card ${moduleName}__card ${
                                    pendingAgents.has(agent.id) ? `${moduleName}__card--pending` : ''
                                }${
                                    agent.agent_module.some(module => Number(module.locations?.length) > 0)
                                        ? ` ${moduleName}__card--active`
                                        : ` ${moduleName}__card--inactive`
                                }`}
                            >
                                <div className="header">{agent.name}</div>
                                <CardContent>
                                    {agent.agent_module.map(module => (
                                        <div key={module.software_id} className={`${moduleName}__module`}>
                                            <div className={`${moduleName}__module-name`}>
                                                {getSoftwareNameById(module.software_id, software)}
                                            </div>
                                            {loadingLocations ? (
                                                <ReactLoading type="spin" color={mainBlue} height={20} width={20} />
                                            ) : (
                                                module.locations
                                                    ?.sort((a, b) => a.name?.localeCompare(b.name))
                                                    .map(location => (
                                                        <div
                                                            key={location.name}
                                                            className={`${moduleName}__location`}
                                                        >{`- ${location.name}`}</div>
                                                    ))
                                            )}
                                        </div>
                                    ))}
                                    <div className={`${moduleName}__last-sync`}>
                                        {agent.agent_type != null && agent.agent_type !== AgentType.UNKNOWN ? (
                                            <div style={{ marginBottom: '4px' }}>
                                                Agent Type{': '}
                                                {agentTypeMap[agent.agent_type]}
                                                <br />
                                            </div>
                                        ) : null}
                                        Last Sync{': '}
                                        {agent.last_sync ? moment(agent.last_sync).format('MM/DD/YYYY, h:mm a') : '/'}
                                    </div>
                                </CardContent>
                                <CardActions>
                                    <div className={`buttons buttons-space-between`}>
                                        {accessAgentDashboard ? (
                                            <div className={`diagnostics-button-wrapper`}>
                                                <Button
                                                    className="button"
                                                    size="small"
                                                    onClick={openAgentDiagnostics(agent)}
                                                >
                                                    Diagnostics
                                                </Button>
                                                <CustomTooltip
                                                    style={{ width: '240px' }}
                                                    position="top-right"
                                                    text={
                                                        <div>
                                                            This page can only be accessed while connected to
                                                            GlobalProtect.
                                                        </div>
                                                    }
                                                >
                                                    <Icon
                                                        style={{
                                                            left: '-2px',
                                                            position: 'absolute',
                                                            top: '4px',
                                                            fontSize: '18px',
                                                        }}
                                                    >
                                                        info
                                                    </Icon>
                                                </CustomTooltip>
                                            </div>
                                        ) : (
                                            <div className="empty-button"></div>
                                        )}
                                        <Button className="button" size="small" onClick={handleEdit(agent)}>
                                            Edit
                                        </Button>
                                        <Button className="button" size="small" onClick={handleRemove(agent)}>
                                            Remove
                                        </Button>
                                    </div>
                                </CardActions>
                            </Card>
                        ))
                    ) : (
                        <div />
                    )}
                    {pagination && allRows > PAGE_SIZE && (
                        <div className={`${moduleName}__paginator-wrapper`}>
                            <Paginator
                                currentPage={searchPage}
                                paginationInfo={pagination}
                                selectPage={handlePageChange}
                            />
                        </div>
                    )}
                    {isLoadingAgents && !agentsLoadedAtLeastOnce && (
                        <div className={`${moduleName}__loading-agents-container`}>
                            <div className={`${moduleName}__agents-spinner-overlay`}>
                                <CircularProgress size={50} className={`${moduleName}__agents-spinner`} />
                            </div>
                        </div>
                    )}
                    {isLoadingAgents && agentsLoadedAtLeastOnce && (
                        <div className={`${moduleName}__loading-agents-overlay-transparent`}>
                            <CircularProgress size={50} className={`${moduleName}__agents-spinner`} />
                        </div>
                    )}
                </div>
                {isModalOpened && (
                    <IntegrationsForm
                        practice={practice}
                        onClose={handleCloseModal}
                        isModalOpened={isModalOpened}
                        externalAgentId={selectedAgent?.external_agent_id}
                        agentId={selectedAgent?.id}
                        setSavedAgentMessage={(agent: IntegrationsAgent) => handleSuccessMessage(agent)}
                        software={software}
                    />
                )}
                <ConfirmModal
                    title={`Are you sure you want to remove this agent?`}
                    subtitle="Removing agents will break features relying on an integration to the PMS."
                    discardText="Cancel"
                    confirmText="Remove"
                    open={!!deleteAgentConfirmation}
                    onClose={handleCancelRemove}
                    onConfirm={handleRemoveConfirm}
                    onDiscard={handleCancelRemove}
                />
            </div>
            {agentsLoadedAtLeastOnce && (
                <div className={`${moduleName}__add-button-footer`} onClick={handleOpenModal}>
                    <div className="button">
                        <i className="material-icons add-icon">add</i>
                        Add Agent
                    </div>
                </div>
            )}
        </>
    )
}
