import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Modal from '@mui/material/Modal'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import classNames from 'classnames'

import { setRedirect } from '../../appActions'
import { RootState } from '../../appReducer'
import { AccessChatList, AccessReferralsList, Amplify, GenerateAmplifyWebCode } from '../../models/enums'
import ChatterAlertManager from '../../modules/amplify/chatter_alert/ChatterAlertManager'
import ReferralModal from '../../modules/referrals/ReferralModal'
import { useAppDispatch } from '../../util/useAppDispatch'
import { fetchPracticeHasEmailNotificationSubscribers, fetchPracticeLocations } from '../practices/actions'
import InfoMessage from '../shared/info-message/info-message'

import MetabaseData from './data/MetabaseData'
import { fetchSubscriberList } from './actions'
import NotificationManager from './NotificationManager'
import { WebCodeIcon } from './WebCodeIcon'

import './AmplifyMetrics.sass'

export type Props = {
    practice: Models.Practice
    onOpenWebCodeManager: () => void
}

const AmplifyMetrics = (props: Props) => {
    const [firstLoadDone, setFirstLoadDone] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showNotificationManager, setShowNotificationManager] = useState(false)
    const [showChatterAlertManager, setShowChatterAlertManager] = useState(false)
    const [notificationsLoader, setNotificationsLoader] = useState(false)
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [newTestReferral, setNewTestReferral] = useState(false)
    const [newManualReferral, setNewManualReferral] = useState(false)

    const { practice, onOpenWebCodeManager } = props
    const { hasEmailNotificationSubscribers } = practice
    const disableCreateReferralsBtn = !hasEmailNotificationSubscribers

    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)
    const subscribers = useSelector((state: RootState) => state.amplify.subscribers[practice.id])

    const amplifyProductInactive = !practice.products.some(p => p.value === Amplify.value && p.active)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (practice && !firstLoadDone) {
            setFirstLoadDone(true)
            dispatch(fetchSubscriberList(practice))
        }
    }, [dispatch, practice, firstLoadDone])

    useEffect(() => {
        if (practice && hasEmailNotificationSubscribers === null) {
            dispatch(fetchPracticeHasEmailNotificationSubscribers(practice))
        }
    }, [dispatch, practice, hasEmailNotificationSubscribers])

    const onCloseCreateModal = () => {
        setShowCreateModal(false)
        setNewTestReferral(false)
        setNewManualReferral(false)
    }

    const onCloseNotificationManager = () => {
        setShowNotificationManager(false)
    }

    const onCloseChatterAlertManager = () => {
        setShowChatterAlertManager(false)
    }

    const onShowConfirmationSnackbar = () => {
        setShowSnackbar(true)
    }

    const closeSnackbar = () => {
        setShowSnackbar(false)
    }

    const onShowCreateReferralModal = () => {
        setShowCreateModal(true)
        setNewTestReferral(false)
        setNewManualReferral(true)
    }

    const onShowCreateTestReferralModal = () => {
        setShowCreateModal(true)
        setNewTestReferral(true)
    }

    const onShowNotificationManager = async () => {
        setNotificationsLoader(true)
        await dispatch(fetchPracticeLocations(practice.id))
        setNotificationsLoader(false)
        setShowNotificationManager(true)
    }

    const onShowChatAlertManager = () => {
        setShowChatterAlertManager(true)
    }

    const onViewChatHistory = () => {
        dispatch(setRedirect(`/diagnostics/${practice.id}/chat-center`))
    }

    const onViewDetails = () => {
        dispatch(setRedirect(`/diagnostics/${practice.id}/amplify`))
    }

    if (!account || !subscribers) {
        return (
            <div className="circular-progress-loader-wrapper">
                <div className="circular-progress-loader">
                    <CircularProgress
                        className="circular-progress-spinner"
                        size={50}
                        color="primary"
                        variant="indeterminate"
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={classNames('amplify-metrics')}>
            <div className="actions">
                <Button
                    className={classNames('new-referral', {
                        disabled: disableCreateReferralsBtn,
                        inactive: amplifyProductInactive,
                    })}
                    onClick={() => onShowCreateReferralModal()}
                    variant="text"
                    disabled={disableCreateReferralsBtn}
                    size="large"
                >
                    <i className="material-icons icon">add</i>
                    Request
                </Button>
                {account.isSimplifeyeStaff && (
                    <Button
                        className={classNames('test-referral', {
                            disabled: disableCreateReferralsBtn,
                            inactive: amplifyProductInactive,
                        })}
                        onClick={() => onShowCreateTestReferralModal()}
                        variant="text"
                        disabled={disableCreateReferralsBtn}
                    >
                        <i className="material-icons icon">add</i>
                        Test Request
                    </Button>
                )}
                <Button
                    className={classNames('notification-manager', {
                        inactive: amplifyProductInactive,
                    })}
                    onClick={onShowNotificationManager}
                    variant="text"
                >
                    {notificationsLoader ? (
                        <div className="circular-progress">
                            <CircularProgress style={{ color: 'white' }} size={18} />
                        </div>
                    ) : (
                        <i className="material-icons icon">settings</i>
                    )}
                    Notifications
                </Button>
                {account.isSimplifeyeStaff && (
                    <Button
                        className={classNames('chat-alert-manager', {
                            inactive: amplifyProductInactive,
                        })}
                        onClick={onShowChatAlertManager}
                        variant="text"
                    >
                        <i className="material-icons icon">settings</i>
                        Chatter Alerts
                    </Button>
                )}
                {account.hasAccess(GenerateAmplifyWebCode) && (
                    <Button
                        className={classNames('web-code-manager', {
                            inactive: amplifyProductInactive,
                        })}
                        onClick={onOpenWebCodeManager}
                        variant="text"
                    >
                        <span className="webcode-icon">
                            <WebCodeIcon />
                        </span>
                        web codes
                    </Button>
                )}
                {account.hasAccess(AccessReferralsList) && (
                    <Button
                        className={classNames('details', {
                            inactive: amplifyProductInactive,
                        })}
                        onClick={onViewDetails}
                        variant="text"
                    >
                        <i className="material-icons icon">device_hub</i>
                        Requests
                    </Button>
                )}
                {account.hasAccess(AccessChatList) && (
                    <Button
                        className={classNames('chat-history', {
                            inactive: amplifyProductInactive,
                        })}
                        onClick={onViewChatHistory}
                        variant="text"
                    >
                        <i className="material-icons icon">message</i>
                        Chats
                    </Button>
                )}
            </div>
            {showNotificationManager && (
                <Modal
                    className="pst-modal notification-manager-modal"
                    open={true}
                    onClose={onCloseNotificationManager}
                >
                    <div className="contents">
                        <div className="close-modal-button" onClick={onCloseNotificationManager}>
                            <i className="material-icons">close</i>
                        </div>
                        <NotificationManager practice={practice} />
                    </div>
                </Modal>
            )}
            {showChatterAlertManager && (
                <Modal
                    className="pst-modal chatter-alert-manager-modal"
                    open={true}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseChatterAlertManager()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className="contents">
                        <div className="close-modal-button" onClick={onCloseChatterAlertManager}>
                            <i className="material-icons">close</i>
                        </div>
                        <ChatterAlertManager practice={practice} />
                    </div>
                </Modal>
            )}
            {showCreateModal && (
                <ReferralModal
                    pid={practice.id}
                    onClose={onCloseCreateModal}
                    newTestReferral={newTestReferral}
                    newManualReferral={newManualReferral}
                    practice={practice}
                    onCreate={() => {
                        onCloseCreateModal()
                        onShowConfirmationSnackbar()
                    }}
                    onUpdate={() => {
                        onCloseCreateModal()
                        onShowConfirmationSnackbar()
                    }}
                    account={account}
                />
            )}

            {process.env.REACT_APP_ENV === 'production' ? (
                <MetabaseData practice={practice} />
            ) : (
                <InfoMessage isShown={true}>Metabase is only available in production.</InfoMessage>
            )}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={showSnackbar}
                autoHideDuration={2500}
                onClose={closeSnackbar}
            >
                <SnackbarContent className="snackbar" message={<span>Request Sent!</span>} />
            </Snackbar>
        </div>
    )
}

export default AmplifyMetrics
