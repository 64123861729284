import * as React from 'react'
import classNames from 'classnames'

import AdminAccount from '../../models/AdminAccount'

import AmplifyMetrics from './AmplifyMetrics'
import WebCodeManager from './WebCodeManager'

import './AmplifyTab.sass'

export type AmplifyTabProps = {
    practice: Models.Practice
    account: AdminAccount
}

export type AmplifyTabState = {
    showAmplifyMetrics: boolean
    showWebCodeManager: boolean
}

type Props = AmplifyTabProps

class AmplifyTab extends React.Component<Props, AmplifyTabState> {
    constructor(props: Props) {
        super(props)
        this.state = {
            showAmplifyMetrics: true,
            showWebCodeManager: false,
        }
    }

    onCloseWebCodeManager = () => {
        this.setState({
            showWebCodeManager: false,
            showAmplifyMetrics: true,
        })
    }

    onOpenWebCodeManager = () => {
        this.setState({
            showWebCodeManager: true,
            showAmplifyMetrics: false,
        })
    }

    render() {
        const { practice, account } = this.props
        return (
            <div className={classNames('amplify-tab')}>
                {this.state.showAmplifyMetrics && (
                    <AmplifyMetrics practice={practice} onOpenWebCodeManager={this.onOpenWebCodeManager} />
                )}
                {this.state.showWebCodeManager && (
                    <WebCodeManager practice={practice} onBack={this.onCloseWebCodeManager} account={account} />
                )}
            </div>
        )
    }
}

export default AmplifyTab
