import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Icon from '@mui/material/Icon'
import uniq from 'lodash/uniq'

import { getSoftwareNameById } from '../../../ApiV2/mappers/v2/integrations'
import { RootState } from '../../../appReducer'
import {
    Chairfill as ChairfillProduct,
    DirectScheduling as DirectSchedulingProduct,
    Reviews as ReviewsProduct,
    SimplifeyePayments as PaymentsProduct,
} from '../../../models/enums'
import { CustomFieldType } from '../../../models/enums'
import {
    AgentType,
    agentTypeMap,
    IntegrationsAgent,
    IntegrationsAgentLocations,
    IntegrationsSoftware,
} from '../../../models/v2/Integrations'
import { useAppDispatch } from '../../../util/useAppDispatch'
import CopyText from '../../shared/CopyText'
import CustomMultiselectFieldV2 from '../../shared/custom-fields/custom-multiselect-field-v2/CustomMultiselectFieldV2'
import CustomField from '../../shared/custom-fields/CustomField'
import ErrorMessage from '../../shared/error-message/error-message'

import { saveAgentName, saveLocationPairing, savePaymentsAllowWriteback, updateLocationAgent } from './actions'
import AllowWriteback from './IntegrationsAllowWriteback'

import './Integrations.sass'

const moduleName = 'integrations'

export const SOFTWARE_TYPES = {
    DENTRIX: 'DENTRIX',
    DENTRIX_ENTERPRISE: 'DENTRIX_ENTERPRISE',
    EAGLESOFT: 'EAGLESOFT',
    OPENDENTAL: 'OPENDENTAL',
    QUICKBOOKS: 'QUICKBOOKS',
    VYNE_SYNC: 'VYNE_SYNC',
}

interface Props {
    practice: Models.Practice
    software: IntegrationsSoftware[]
    setSavedAgentMessage: (agent: IntegrationsAgent) => void
    onClose: () => void
    selectedAgent: IntegrationsAgent
    createdAgentId: React.MutableRefObject<string | undefined>
}

export const IntegrationsLocationPairingForm = ({
    practice,
    onClose,
    setSavedAgentMessage,
    software,
    selectedAgent,
    createdAgentId,
}: Props) => {
    const [hasLocationUpdates, setHasLocationUpdates] = useState(false)
    const [agentNameEditMode, setAgentNameEditMode] = useState(false)
    const [hasAgentNameUpdates, setHasAgentNameUpdates] = useState(false)
    const [selectedAgentName, setSelectedAgentName] = useState<string>(selectedAgent?.name)

    const isPending = useSelector(
        (state: RootState) => state.v2.integrations.heartbeat[practice.id]?.isPending ?? false,
    )
    const errorMessage = useSelector(
        (state: RootState) => state.v2.integrations.heartbeat[practice.id]?.errorMessage ?? false,
    )

    const [isUpdatingAllowWriteback, setIsUpdatingAllowWriteback] = useState(false)
    const [currentAllowWriteback, setCurrentAllowWriteback] = useState<{
        [locationId: string]: boolean
    }>({})
    const [allowWritebacksToSave, setAllowWritebacksToSave] = useState<{
        [locationId: string]: boolean
    }>({})

    const listRef = useRef<HTMLUListElement | null>(null)

    const hasLocationOrAgentNameUpdates = hasLocationUpdates || hasAgentNameUpdates
    const allPracticeLocations = useSelector((state: RootState) => state.v2.integrations.practiceLocations[practice.id])
    const agents = useSelector((state: RootState) => state.v2.integrations.heartbeat[practice.id]?.agents || [])

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (errorMessage !== '') {
            listRef.current?.firstElementChild?.scrollIntoView()
        }
    }, [errorMessage])

    const usedLocations = useMemo(() => {
        const used: string[] = []

        agents.forEach(agent => {
            agent.agent_module.forEach(agentModule => {
                agentModule.locations?.forEach(externalLocation => {
                    if (externalLocation.id) {
                        used.push(externalLocation.id)
                    }
                })
            })
        })
        return uniq(used)
    }, [agents])

    const hasProduct = (productName: Api.ProductType) => !!practice.products.find(p => p.value === productName)?.active

    interface IOptions {
        directScheduling: boolean
        heartbeat: boolean
        chairfill: boolean
        payments: boolean
        reviews: boolean
        value: string
    }

    const handleUpdateLocationPair = (externalLocation: IntegrationsAgentLocations, { ...options }: IOptions) => {
        dispatch(
            updateLocationAgent(
                externalLocation.id,
                options.directScheduling,
                options.heartbeat,
                options.chairfill,
                options.payments,
                options.reviews,
                options.value,
            ),
        )

        setHasLocationUpdates(true)
    }

    const handleSavePairing = async () => {
        if (selectedAgent && selectedAgentName !== '' && hasLocationOrAgentNameUpdates) {
            const pairs = selectedAgent.agentModules.reduce((list, aModule) => {
                return [
                    ...list,
                    ...aModule.agentLocations.map(location => ({
                        external_location_id: location.id,
                        practice_location_id: location.practiceLocation?.heartbeatPracticeLocationId || undefined,
                        has_direct_scheduling: location.directScheduling,
                        has_heartbeat: location.heartbeat,
                        has_chairfill: location.chairfill,
                        has_payments: location.payments,
                        has_reviews: location.reviews,
                    })),
                ]
            }, [])

            try {
                await dispatch(saveLocationPairing(practice.id, createdAgentId.current!, pairs))
                if (hasAgentNameUpdates) {
                    await dispatch(saveAgentName(practice.id, createdAgentId.current!, selectedAgentName))
                }
                setSavedAgentMessage(selectedAgent)
                onClose()
            } catch (err) {}
        }
        if (Object.keys(allowWritebacksToSave).length > 0) {
            setIsUpdatingAllowWriteback(true)
            const promiseList = []
            for (let locationId in allowWritebacksToSave) {
                promiseList.push(dispatch(savePaymentsAllowWriteback(locationId, allowWritebacksToSave[locationId])))
            }
            await Promise.all(promiseList)
            setIsUpdatingAllowWriteback(false)
            setSavedAgentMessage(selectedAgent)
            onClose()
        }
    }

    const handleOpenEditAgentName = () => {
        setAgentNameEditMode(true)
    }

    const regularExternalLocations = selectedAgent.agentModules.reduce(
        (externalLocations, module) =>
            getSoftwareNameById(module.software.id, software) === SOFTWARE_TYPES.QUICKBOOKS
                ? externalLocations
                : [...externalLocations, ...module.agentLocations],
        [],
    )

    const quickbooksExternalLocations = selectedAgent.agentModules.reduce(
        (externalLocations, module) =>
            getSoftwareNameById(module.software.id, software) === SOFTWARE_TYPES.QUICKBOOKS
                ? [...externalLocations, ...module.agentLocations]
                : externalLocations,
        [],
    )

    const isDisabled = (locationId: string, externalLocationId: number, moduleName: string) => {
        if (usedLocations?.includes(locationId)) {
            return true
        }
        if (moduleName === SOFTWARE_TYPES.QUICKBOOKS) {
            return quickbooksExternalLocations
                .filter(loc => loc.id !== externalLocationId)
                .some(loc => loc.practiceLocation?.heartbeatPracticeLocationId === locationId)
        }
        return regularExternalLocations
            .filter(loc => loc.id !== externalLocationId)
            .some(loc => loc.practiceLocation?.heartbeatPracticeLocationId === locationId)
    }

    const onChangeWriteback = (locationId: string, allowWriteback: boolean) => {
        setCurrentAllowWriteback({
            ...currentAllowWriteback,
            [locationId]: allowWriteback,
        })
    }

    const updateAllowWriteback = (locationId: string, allowWriteback: boolean) => {
        setAllowWritebacksToSave({
            ...allowWritebacksToSave,
            [locationId]: allowWriteback,
        })
    }

    const sortedLocations = useMemo(() => allPracticeLocations.sort((a, b) => a.name.localeCompare(b.name)), [
        allPracticeLocations,
    ])

    const generateLocationSoftwarePairingItems = (externalLocationId: number, softwareModuleId: number) =>
        sortedLocations.map(location => ({
            id: location.id,
            name: location.name,
            disabled: isDisabled(location.id, externalLocationId, getSoftwareNameById(softwareModuleId, software)),
        }))

    return (
        <>
            <div className={`${moduleName}__pairing`} ref={listRef as React.RefObject<any>}>
                {agentNameEditMode ? (
                    <div className={`${moduleName}__agent-name-edit-wrapper`}>
                        <CustomField
                            required={true}
                            value={selectedAgentName}
                            customFieldType={CustomFieldType.INPUT}
                            label={`Agent Name *`}
                            error={selectedAgentName === ''}
                            errorMessage="This field is required"
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                setSelectedAgentName(event.target.value)
                                setHasAgentNameUpdates(true)
                            }}
                        />
                    </div>
                ) : (
                    <h1 className={`name-header ${moduleName}__name-header`}>
                        {selectedAgent?.name}{' '}
                        <i
                            onClick={handleOpenEditAgentName}
                            className={`material-icons ${moduleName}__agent-name-edit-icon`}
                        >
                            edit
                        </i>
                    </h1>
                )}
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                {selectedAgent.agentModules.map(module => {
                    return (
                        <div key={module.id} className={`${moduleName}__pairing-module`}>
                            <div className={`${moduleName}__pairing-title`}>
                                {getSoftwareNameById(module.software.id, software)}
                            </div>
                            {selectedAgent.agentType != null && selectedAgent.agentType !== AgentType.UNKNOWN ? (
                                <div style={{ marginBottom: '4px' }}>
                                    Agent Type{': '}
                                    {agentTypeMap[selectedAgent.agentType]}
                                    <br />
                                </div>
                            ) : null}
                            <p>
                                Please pair each {getSoftwareNameById(module.software.id, software)} location with the
                                correct Vyne Trellis location.
                            </p>
                            {module.agentLocations
                                .sort((a, b) => a.name?.localeCompare(b.name))
                                .map(externalLocation => (
                                    <div className={`${moduleName}__pair-container`} key={externalLocation.id}>
                                        <div className={`${moduleName}__pair`}>
                                            <div className={`${moduleName}__pair-external`}>
                                                <CustomField
                                                    value={externalLocation.name}
                                                    customFieldType={CustomFieldType.INPUT}
                                                    label={`${getSoftwareNameById(
                                                        module.software.id,
                                                        software,
                                                    )} Location`}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className={`${moduleName}__pair-link`}>
                                                <Icon>link</Icon>
                                            </div>

                                            <div className={`${moduleName}__pair-internal`}>
                                                <CustomMultiselectFieldV2
                                                    items={generateLocationSoftwarePairingItems(
                                                        externalLocation.id,
                                                        module.software.id,
                                                    )}
                                                    maxSelected={1}
                                                    selectedItems={[
                                                        externalLocation.practiceLocation
                                                            ?.heartbeatPracticeLocationId || '',
                                                    ]}
                                                    search={Boolean(sortedLocations.length > 8)}
                                                    searchPlaceholder="Search Location"
                                                    keyProperty="id"
                                                    displayProperty="name"
                                                    placeholder="Select Location"
                                                    label="Pair Location with..."
                                                    disabled={
                                                        !!externalLocation.practiceLocation &&
                                                        !Boolean(externalLocation.practiceLocation.updated)
                                                    }
                                                    onSelectElement={(values: string[]) => {
                                                        handleUpdateLocationPair(externalLocation, {
                                                            directScheduling: externalLocation.directScheduling,
                                                            heartbeat: externalLocation.heartbeat,
                                                            chairfill: externalLocation.chairfill,
                                                            payments: externalLocation.payments,
                                                            reviews: externalLocation.reviews,
                                                            value: values[0],
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${moduleName}__checkboxes`}>
                                            {hasProduct(ChairfillProduct.value) &&
                                                [
                                                    SOFTWARE_TYPES.DENTRIX,
                                                    SOFTWARE_TYPES.EAGLESOFT,
                                                    SOFTWARE_TYPES.DENTRIX_ENTERPRISE,
                                                    SOFTWARE_TYPES.OPENDENTAL,
                                                ].includes(getSoftwareNameById(module.software.id, software)) && (
                                                    <div className={`${moduleName}__checkbox`}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={
                                                                        !externalLocation.practiceLocation ||
                                                                        !externalLocation.practiceLocation
                                                                            .heartbeatPracticeLocationId ||
                                                                        [SOFTWARE_TYPES.DENTRIX_ENTERPRISE].includes(
                                                                            getSoftwareNameById(
                                                                                module.software.id,
                                                                                software,
                                                                            ),
                                                                        )
                                                                    }
                                                                    color="primary"
                                                                    checked={externalLocation.chairfill}
                                                                    onChange={(
                                                                        event: React.ChangeEvent<HTMLInputElement>,
                                                                    ) => {
                                                                        handleUpdateLocationPair(externalLocation, {
                                                                            directScheduling:
                                                                                externalLocation.directScheduling,
                                                                            heartbeat: externalLocation.heartbeat,
                                                                            chairfill: event.target.checked,
                                                                            payments: externalLocation.payments,
                                                                            reviews: externalLocation.reviews,
                                                                            value:
                                                                                externalLocation.practiceLocation
                                                                                    .heartbeatPracticeLocationId,
                                                                        })
                                                                    }}
                                                                />
                                                            }
                                                            label="Campaigns"
                                                        />
                                                    </div>
                                                )}
                                            {hasProduct(DirectSchedulingProduct.value) && (
                                                <div className={`${moduleName}__checkbox`}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                disabled={
                                                                    !externalLocation.practiceLocation ||
                                                                    !externalLocation.practiceLocation
                                                                        .heartbeatPracticeLocationId
                                                                }
                                                                color="primary"
                                                                checked={externalLocation.directScheduling}
                                                                onChange={(
                                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                                ) => {
                                                                    handleUpdateLocationPair(externalLocation, {
                                                                        directScheduling: event.target.checked,
                                                                        heartbeat: externalLocation.heartbeat,
                                                                        chairfill: externalLocation.chairfill,
                                                                        payments: externalLocation.payments,
                                                                        reviews: externalLocation.reviews,
                                                                        value:
                                                                            externalLocation.practiceLocation
                                                                                .heartbeatPracticeLocationId,
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                        label="Online Scheduling"
                                                    />
                                                </div>
                                            )}
                                            {hasProduct(ReviewsProduct.value) && (
                                                <div className={`${moduleName}__checkbox`}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                disabled={
                                                                    !externalLocation.practiceLocation ||
                                                                    !externalLocation.practiceLocation
                                                                        .heartbeatPracticeLocationId
                                                                }
                                                                color="primary"
                                                                checked={externalLocation.reviews}
                                                                onChange={(
                                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                                ) => {
                                                                    handleUpdateLocationPair(externalLocation, {
                                                                        directScheduling:
                                                                            externalLocation.directScheduling,
                                                                        heartbeat: externalLocation.heartbeat,
                                                                        chairfill: externalLocation.chairfill,
                                                                        payments: externalLocation.payments,
                                                                        reviews: event.target.checked,
                                                                        value:
                                                                            externalLocation.practiceLocation
                                                                                .heartbeatPracticeLocationId,
                                                                    })
                                                                }}
                                                            />
                                                        }
                                                        label="Reviews"
                                                    />
                                                </div>
                                            )}
                                            {hasProduct(PaymentsProduct.value) && (
                                                <div className={`${moduleName}__checkbox`}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                disabled={
                                                                    !externalLocation.practiceLocation ||
                                                                    !externalLocation.practiceLocation
                                                                        .heartbeatPracticeLocationId
                                                                }
                                                                color="primary"
                                                                checked={externalLocation.payments}
                                                                onChange={(
                                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                                ) => {
                                                                    handleUpdateLocationPair(
                                                                        externalLocation,

                                                                        {
                                                                            directScheduling:
                                                                                externalLocation.directScheduling,
                                                                            heartbeat: externalLocation.heartbeat,
                                                                            chairfill: externalLocation.chairfill,
                                                                            payments: event.target.checked,
                                                                            reviews: externalLocation.reviews,
                                                                            value:
                                                                                externalLocation.practiceLocation
                                                                                    .heartbeatPracticeLocationId,
                                                                        },
                                                                    )
                                                                    if (event.target.checked) {
                                                                        onChangeWriteback(
                                                                            externalLocation.practiceLocation
                                                                                .heartbeatPracticeLocationId,
                                                                            true,
                                                                        )
                                                                        updateAllowWriteback(
                                                                            externalLocation.practiceLocation
                                                                                .heartbeatPracticeLocationId,
                                                                            true,
                                                                        )
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        label="Simplifeye Payments"
                                                    />
                                                    {!!externalLocation.practiceLocation &&
                                                        !!externalLocation.practiceLocation
                                                            .heartbeatPracticeLocationId &&
                                                        externalLocation.payments && (
                                                            <AllowWriteback
                                                                locationId={
                                                                    externalLocation.practiceLocation
                                                                        .heartbeatPracticeLocationId
                                                                }
                                                                checked={
                                                                    currentAllowWriteback[
                                                                        externalLocation.practiceLocation
                                                                            .heartbeatPracticeLocationId
                                                                    ]
                                                                }
                                                                onChangeWriteback={onChangeWriteback}
                                                                onUpdateWriteback={updateAllowWriteback}
                                                            />
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )
                })}
                <div className={`${moduleName}__secrets`}>
                    <p>
                        <CopyText text={selectedAgent.accessKey}>
                            <strong>Access Key:</strong> {selectedAgent.accessKey}
                        </CopyText>
                    </p>
                    <p>
                        <CopyText text={selectedAgent.secretKey}>
                            <strong>Secret Key:</strong> {selectedAgent.secretKey}
                        </CopyText>
                    </p>
                </div>
                <div className={`${moduleName}__footer`}>
                    <Button
                        disabled={
                            ((!hasLocationOrAgentNameUpdates || selectedAgentName === '') &&
                                Object.keys(allowWritebacksToSave).length === 0) ||
                            isPending ||
                            isUpdatingAllowWriteback
                        }
                        className={`${moduleName}__submit-button`}
                        onClick={handleSavePairing}
                    >
                        SAVE
                    </Button>
                </div>
            </div>
        </>
    )
}
