import React, { Fragment, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Icon } from '@mui/material'
import Modal from '@mui/material/Modal'
import classNames from 'classnames'
import iassign from 'immutable-assign'
import validators from 'validator'

import { RootState } from '../../appReducer'
import { AmplifyWebCodeSurveySection, SurveySectionQuestion, ValueType } from '../../models/AmplifyWebCodeSurveys'
import { ManageWebCodes } from '../../models/enums'
import { CustomFieldType } from '../../models/enums'
import { VALID_URL_PROTOCOL_REGEX } from '../../modules/amplify/shared/validator'
import InputField from '../../modules/practices/locations/edit-survey/survey-rendered/input-field/InputField'
import { fetchPracticeLocations } from '../practices/actions'
import CustomInput from '../shared/custom-fields/CustomField'
import CustomTooltip from '../shared/custom-tooltip/CustomTooltip'
import { veryLightGreen } from '../shared/styles/colors'

import LocationsFlatList from './customization/LocationsFlatList'
import { validator } from './shared/validator'
import * as actions from './actions'
import WebCodeConfirmationBox from './WebCodeConfirmationBox'

import '../../modules/practices/locations/edit-survey/survey-rendered/SurveyRendered.sass'
import './WebCodeForm.sass'

const moduleName = 'web-code-form'

export type ConfirmationBoxType = 'softDeleteWebCode'

type WebCodeFormState = {
    showConfirmationBox: boolean
    confirmationBoxCallbackFn?: () => void
    confirmationBoxType?: ConfirmationBoxType
    confirmationBoxTitle?: string
}

export interface ConfirmationBoxParams {
    type: ConfirmationBoxType
    callback: () => void
    title?: string
}

export type Props = {
    practice: Models.Practice
    webCode?: Models.WebCode
    searchPage?: number
    searchValue?: string
    onClose: (closeMsg: string) => void
}

const WebCodeForm = ({ practice, webCode, searchPage = 1, searchValue = '', onClose }: Props) => {
    const isEditMode = webCode ? true : false
    const isAddingNew = !isEditMode
    const dispatch = useDispatch()
    const {
        activeWebCodeId,
        webcodes,
        surveys,
    }: { activeWebCodeId: string; webcodes: Models.WebCode[]; surveys: AmplifyWebCodeSurveySection[] } = useSelector(
        (state: any) => ({
            activeWebCodeId: state.amplify?.activeWebCode, // activeWebCode is added to state on create new
            webcodes: state.amplify?.webCodes?.[practice?.id] ?? [],
            surveys: state.amplify?.webCodeSurveys ?? {},
        }),
    )
    const account = useSelector((state: RootState) => state.app.self && state.app.self.account)
    const enableSoftDelete = useSelector((state: RootState) => state.app.featureFlags?.softDeleteWebcodes)
    const webCodeId = webCode ? webCode.id : activeWebCodeId
    const activeWebcode = webcodes.filter(webc => webc.id === webCodeId)?.[0]
    const closeMsg = `${activeWebcode?.url} has been saved`
    const activeSurvey = surveys?.[webCodeId]?.[0]
    const practiceId = practice?.id

    const practiceLocationIds = activeWebcode?.practiceLocationIds
        ?.filter(location => location.enabled)
        .map((locationId: any) => locationId.practiceLocationId)

    const [loading, setLoading] = useState<boolean>(false)
    const [url, setUrl] = useState<string>(activeWebcode?.url || '')
    const [urlErr, setUrlErr] = useState<boolean>(false)
    const [locationIds, setLocationIds] = useState<any>(practiceLocationIds || [])
    const [isDirty, setIsDirty] = useState<any>({ url: false, location: false })

    const [formData, setFormData] = useState<WebCodeFormState>({
        showConfirmationBox: false,
        confirmationBoxCallbackFn: undefined,
        confirmationBoxType: undefined,
        confirmationBoxTitle: undefined,
    })

    const isValidLocation = locationIds.length > 0
    const isValidUrl =
        validators.isURL(url, { protocols: ['https', 'http'], require_protocol: true, require_valid_protocol: true }) &&
        !urlErr
    const isWebsiteSectionValid = isValidLocation && isValidUrl
    const isSurveySectionValid = activeSurvey?.questions.every((q: any) => q.isValid)
    const isSurveySectionDirty = activeSurvey?.questions.some((q: any) => q.isDirty)
    const isFormDirty = isDirty.location || isDirty.url || isSurveySectionDirty

    const onChangeLocations = async (ids: any) => {
        setLocationIds(ids)
        setIsDirty({ location: true, url: isDirty.url })
    }

    const onUrlChange = async (url: any) => {
        setUrlErr(false)
        setUrl(url)
        setIsDirty({ url: true, location: isDirty.location })
    }
    const handleChangeInput = (survey: AmplifyWebCodeSurveySection, question: SurveySectionQuestion) => (
        value: ValueType,
    ) => {
        const errorMessage = (Array.isArray(value) ? value : [value]).reduce((message, value) => {
            if (message) {
                return message
            }
            return validator(question, value)
        }, '')
        const nextQuestion: SurveySectionQuestion = { ...question }
        if (!nextQuestion.answer_schema) {
            nextQuestion.answer_schema = {}
        }
        nextQuestion.answer_schema.value = value
        nextQuestion.errorMessage = errorMessage || ''
        nextQuestion.isValid = !errorMessage
        nextQuestion.isDirty = true
        dispatch(actions.updateAmplifyWebCodeSurveyQuestion(webCodeId, survey, nextQuestion))
    }

    useEffect(() => {
        dispatch(fetchPracticeLocations(practiceId))
    }, [dispatch, practiceId])

    useEffect(() => {
        const fetchSurveys = async () => {
            setLoading(true)
            await dispatch(actions.getAmplifyWebcodeSurvey(activeWebcode.id, activeWebcode.survey?.id))
            setLoading(false)
        }

        // fetch survey data on edit
        if (isEditMode && activeWebcode?.survey?.id && !activeSurvey) {
            fetchSurveys()
        }
    }, [isEditMode, activeWebcode, activeSurvey, dispatch])

    useEffect(() => {
        return () => {
            dispatch(actions.amplifySetActiveWebCode(''))
        }
    }, [dispatch])

    const onNext = async () => {
        setLoading(true)
        const webCodeStatus = await dispatch(actions.createWebCode(practice, url, locationIds))
        if (webCodeStatus?.errors) {
            setUrlErr(true)
        }
        setLoading(false)
    }
    const onSaveSurvey = async () => {
        setLoading(true)
        const surveyData = await dispatch(actions.saveSurvey(activeWebcode, activeSurvey))
        setLoading(false)
        if (!surveyData?.errors) {
            onClose(closeMsg)
        }
    }
    const onSaveEdit = async () => {
        setLoading(true)
        const webCodeStatus = await dispatch(
            actions.saveWebCode(practice, activeWebcode, {
                url: url,
                practice_location_ids: locationIds,
            }),
        )
        const surveyStatus = await dispatch(actions.saveSurvey(activeWebcode, activeSurvey))
        setLoading(false)
        if (!webCodeStatus.errors && !surveyStatus.errors) {
            onClose(closeMsg)
        }
        if (webCodeStatus.errors) {
            setUrlErr(true)
        }
    }

    const isAddingNewWebsite = isAddingNew && !activeSurvey
    const isAddingNewSurvey = isAddingNew && activeSurvey

    const renderQuestion = (survey: AmplifyWebCodeSurveySection, question: SurveySectionQuestion) => {
        const key = `${question.question_type}_${question.id}`
        switch (question.question_type) {
            case 'input_field':
                return (
                    <InputField
                        question={question}
                        onChange={handleChangeInput(survey, question)}
                        key={key}
                        showErrorText={true}
                    />
                )
            default:
                return <></>
        }
    }

    const renderLocations = () => {
        return (
            <Fragment>
                <h3 className={`${moduleName}__subtitle`}>Location(s) associated with this website</h3>
                <LocationsFlatList
                    practiceId={practiceId}
                    selectedItems={locationIds}
                    onSelectElement={ids => {
                        onChangeLocations(ids)
                    }}
                />

                <p className={`${moduleName}__error`}>
                    {!isValidLocation && isDirty.location && 'Please select location(s)'}
                </p>
            </Fragment>
        )
    }

    const renderWebsiteLabel = () => {
        return (
            <Grid item xs={12} sm={12} md={6}>
                <div className={`survey-question survey-question--input`}>
                    <div className={`survey-question__label`}>What is/are the practice website URL/address(es)?*</div>
                    <div className={`survey-question__fields survey-question--input`}>{url}</div>
                </div>
            </Grid>
        )
    }

    const renderWebsiteInput = () => {
        const urlErrorMsg = () => {
            if (!VALID_URL_PROTOCOL_REGEX.test(url) && isDirty.url) {
                return 'Please enter protocol (ex https://)'
            }
            if (
                (!validators.isURL(url, {
                    protocols: ['https', 'http'],
                    require_protocol: true,
                    require_valid_protocol: true,
                }) &&
                    isDirty.url) ||
                urlErr
            ) {
                return 'URL is invalid.'
            }

            return ''
        }
        const urlError = urlErrorMsg()
        return (
            <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className={`survey-surveys ${
                    isEditMode ? `survey-surveys-webcode-url` : `survey-surveys-webcode-url-add`
                }`}
            >
                <div className={`survey-question survey-question--input`}>
                    <div className={`survey-question__label`}>
                        What is/are the practice website URL/address(es)?*
                        <CustomTooltip
                            text={
                                'Please enter a valid URL including the protocol prefix (Ex. https://vynedental.com/simplifeye)'
                            }
                            position="top-left"
                            style={{ width: '380px', fontWeight: 'normal' }}
                        >
                            <Icon style={{ left: '2px', position: 'absolute', top: '-2px', fontSize: '21px' }}>
                                info
                            </Icon>
                        </CustomTooltip>
                    </div>
                    <div className={`survey-question__fields survey-question--input`}>
                        <CustomInput
                            customFieldType={CustomFieldType.INPUT}
                            placeholder={'https://vynedental.com/simplifeye'}
                            value={url}
                            error={urlError !== ''}
                            errorMessage={urlError}
                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => onUrlChange(event.target.value)}
                        />
                        {urlError && <span className={`${moduleName}__error`}>{urlError}</span>}
                    </div>
                </div>
            </Grid>
        )
    }

    const renderSurveyForm = () => {
        return (
            <div className={'survey-surveys'}>
                {webCode?.surveyMonkeyResponseId && (
                    <div className={`${moduleName}__survey-monkey-id-wrapper`}>
                        SurveyMonkey Response ID:{' '}
                        <span className={`${moduleName}__survey-monkey-id`}>{webCode?.surveyMonkeyResponseId}</span>
                    </div>
                )}
                <div key={activeSurvey?.id} className="survey-section survey-section--edit">
                    <div className="survey-section__wrapper">
                        <Grid container spacing={4}>
                            {isAddingNewSurvey ? renderWebsiteLabel() : renderWebsiteInput()}

                            {activeSurvey?.questions.map((question: SurveySectionQuestion, index: any) => {
                                return (
                                    <Fragment key={`id_${index}`}>
                                        <Grid item key={question.id} {...question.size}>
                                            <div
                                                className={`survey-question survey-question--${question.question_type}`}
                                            >
                                                <div
                                                    className={
                                                        activeSurvey?.firstLabelSize === 'big' && index === 0
                                                            ? 'survey-question__label survey-question__label--big'
                                                            : 'survey-question__label'
                                                    }
                                                >
                                                    {question.question_text.replace('eb-developer', 'eb developer')}
                                                    {question.isRequired && '*'}
                                                    {!question.isRequired && (
                                                        <span className="survey-question__label-optional">
                                                            (optional)
                                                        </span>
                                                    )}
                                                </div>
                                                <div
                                                    className={`survey-question__fields survey-question--${question.question_type}`}
                                                >
                                                    {renderQuestion(activeSurvey, question)}
                                                </div>
                                            </div>
                                        </Grid>
                                    </Fragment>
                                )
                            })}
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }

    const onCloseConfirmationBox = () => {
        setFormData(
            iassign(formData, (next: WebCodeFormState) => {
                next.showConfirmationBox = false
                next.confirmationBoxCallbackFn = undefined
                next.confirmationBoxType = undefined

                return next
            }),
        )
    }

    const onShowConfirmationBox = ({ type, callback, title }: ConfirmationBoxParams) => {
        setFormData(
            iassign(formData, (next: WebCodeFormState) => {
                next.showConfirmationBox = true
                next.confirmationBoxType = type
                next.confirmationBoxCallbackFn = callback
                next.confirmationBoxTitle = title
                return next
            }),
        )
    }

    const onSoftDeleteWebCode = () => {
        const callback = () => {
            dispatch(actions.softDeleteWebCode(practice, activeWebcode, searchPage, searchValue))
            onClose('')
        }

        onShowConfirmationBox({ type: 'softDeleteWebCode', callback })
    }

    const renderForm = () => {
        if (isAddingNewWebsite) {
            return (
                <Fragment>
                    {renderWebsiteInput()} {renderLocations()}
                </Fragment>
            )
        } else if (isAddingNewSurvey) {
            return <Fragment>{renderSurveyForm()}</Fragment>
        } else if (isEditMode) {
            return (
                <Fragment>
                    {renderSurveyForm()} {renderLocations()}
                </Fragment>
            )
        }

        return <Fragment></Fragment>
    }

    const renderFormButton = () => {
        if (isAddingNewWebsite) {
            return (
                <button
                    disabled={!isWebsiteSectionValid}
                    className={classNames('bottom-save-button', { 'can-save': isWebsiteSectionValid })}
                    onClick={onNext}
                >
                    NEXT
                </button>
            )
        } else if (isAddingNewSurvey) {
            return (
                <button
                    disabled={!isSurveySectionValid}
                    className={classNames('bottom-save-button', { 'can-save': isSurveySectionValid })}
                    onClick={onSaveSurvey}
                >
                    SAVE
                </button>
            )
        } else if (isEditMode) {
            return (
                <button
                    disabled={!isWebsiteSectionValid || !isSurveySectionValid || !isFormDirty}
                    className={classNames('bottom-save-button', {
                        'can-save': isWebsiteSectionValid && isSurveySectionValid && isFormDirty,
                    })}
                    onClick={onSaveEdit}
                >
                    SAVE
                </button>
            )
        }

        return <Fragment></Fragment>
    }

    return (
        <div className="contents code-modal code-modal-wide">
            <div className="close-modal-button" onClick={() => onClose('')}>
                <i className="material-icons">close</i>
            </div>
            {formData.showConfirmationBox && (
                <Modal
                    open={true}
                    onClose={(event, reason) => {
                        if (reason !== 'backdropClick') {
                            onCloseConfirmationBox()
                        }
                    }}
                    disableEscapeKeyDown={true}
                >
                    <div className="webcode-confirmation-modal">
                        <div className="close-modal-button" onClick={onCloseConfirmationBox}>
                            <i className="material-icons">close</i>
                        </div>
                        <WebCodeConfirmationBox
                            confirmFn={formData.confirmationBoxCallbackFn}
                            closeFn={onCloseConfirmationBox}
                            type={formData.confirmationBoxType}
                        />
                    </div>
                </Modal>
            )}
            <div className={`${moduleName}`}>
                {loading && (
                    <div className={`${moduleName}__pending`}>
                        <ReactLoading type="spin" color={veryLightGreen} height={120} width={120} />
                    </div>
                )}
                <div className={`${moduleName}__container`}>
                    <div className="name-header-wrapper">
                        <div className="name-header">{practice.name}</div>
                        {!webCode?.active && isEditMode && enableSoftDelete && account?.hasAccess(ManageWebCodes) && (
                            <div className={`${moduleName}__delete-button`}>
                                <Grid item={true}>
                                    <Button className="top-action-btn" onClick={onSoftDeleteWebCode}>
                                        Delete
                                    </Button>
                                </Grid>
                            </div>
                        )}
                    </div>
                    {renderForm()}
                </div>
            </div>
            {renderFormButton()}
        </div>
    )
}

export default WebCodeForm
