import { HeartbeatAgentModuleLocationV2 } from './Practice'

interface IName {
    name: string
}
interface IId {
    id: string
}

type locationsType = HeartbeatAgentModuleLocationV2 & IName & IId

export interface IMappedAgentModule extends HeartbeatPracticeAgentModule {
    locations?: locationsType[]
}

export interface IMappedAgentsWithLocations extends HeartbeatPracticeIntegrationsAgent {
    agent_module: IMappedAgentModule[]
}

export interface IntegrationsSoftware {
    id: number
    moduleTypeName: string
    version: string
}

export interface HeartbeatPracticeAgentModule {
    id: string
    heartbeat_agent_id: string
    external_agent_module_id: number
    software_id: number
}

export interface HeartbeatPracticeIntegrationsAgent {
    access_key: string
    agent_module: HeartbeatPracticeAgentModule[]
    download_url: string
    external_agent_id: number
    id: string
    last_sync: string
    name: string
    practice_id: string
    secret_key: string
    agent_type?: AgentType
}

export interface IntegrationsAgent {
    id: number
    name: string
    accessKey: string
    build: string
    secretKey: string
    agentModules: IntegrationsAgentModule[]
    lastSync: string
    downloadUrl: string
    agentType?: AgentType
}

export interface IntegrationsAgentModule {
    id: string
    software: {
        id: number
        createdAt: string
        updatedAt: string
        version: string
    }
    isDisabled: boolean
    agentLocations: IntegrationsAgentLocations[]
}

export interface IntegrationsAgentLocations {
    id: number
    name: string
    heartbeat: boolean
    directScheduling: boolean
    chairfill: boolean
    payments: boolean
    reviews: boolean
    practiceLocation: {
        id?: number
        name?: string
        heartbeatPracticeLocationId: string
        specialtyValue?: string
        specialtyDisplayName?: string
        updated?: boolean
    }
}

export interface IntegrationsLocationPair {
    external_location_id: number
    practice_location_id?: string
    has_direct_scheduling?: boolean
    has_heartbeat?: boolean
    has_reviews?: boolean
}

export interface IntegrationsAgentsSearchTerms {
    query?: string
    page?: number
    limit?: number
}

export enum AgentType {
    SIMPLIFEYE = 'Simplifeye',
    VYNE = 'Vyne',
    UNKNOWN = 'Unknown',
}

export const agentTypeMap = {
    [AgentType.SIMPLIFEYE]: 'Simplifeye Legacy Agent',
    [AgentType.VYNE]: 'Vyne Sync',
    [AgentType.UNKNOWN]: 'Unknown',
}
