import { NotificationsState, reducer as notificationsReducer } from 'react-notification-system-redux'
import iassign from 'immutable-assign'
import { combineReducers } from 'redux'

import AdminAccount from './models/AdminAccount'
import { PracticeSurveyMap as PracticeSurveyState } from './models/PracticeSurvey'
import { Heartbeat as HeartbeatState } from './models/v2/Heartbeat'
import { AdminState, reducer as adminReducer } from './modules/admin/reducer'
import { ConnectSurveyState, reducer as connectSurveysReducer } from './modules/amplify/connect/reducer'
import { AmplifyState, reducer as amplifyReducer } from './modules/amplify/reducer'
import { ConnectState, persistedReducer as connectReducer } from './modules/chat-center/connect/reducer'
import {
    DirectSchedulingState,
    reducer as directSchedulingReducer,
} from './modules/chat-center/direct-scheduling/reducer'
import { ChatState, reducer as chatReducer } from './modules/chat-center/reducer'
import { DexVoiceState, reducer as dexVoiceReducer } from './modules/dexvoice/reducer'
import {
    AvailabilityState as AvailabilityStateV2,
    reducer as availabilityReducerV2,
} from './modules/direct-scheduling/availability/v2reducer'
import {
    reducer as reschedulingConfigurationReducer,
    ReschedulingConfigurationState,
} from './modules/direct-scheduling/cancel-reschedule-settings/v2reducer'
import { ConfigLogsState, reducer as configLogsReducer } from './modules/direct-scheduling/config-logs/v2reducer'
import {
    reducer as selfSchedulingReducer,
    SelfSchedulingState,
} from './modules/direct-scheduling/self-scheduling/reducer'
import { NavigationState, reducer as navigationReducer } from './modules/navigation/reducer'
import { PaymentsState, reducer as paymentsReducer } from './modules/payments/reducer'
import {
    IntegrationsState as IntegrationsStateV2,
    reducer as integrationsReducerV2,
} from './modules/practices/integrations/reducer'
import practiceSurveyReducer from './modules/practices/locations/edit-survey/reducer'
import {
    PracticeSurveyState as PracticeSurveyStateV2,
    reducer as practiceSurveyReducerV2,
} from './modules/practices/locations/edit-survey/v2reducer'
import practiceLocationsReducer, { PracticeLocationsState } from './modules/practices/locations/reducer'
import { PracticePatientsState, reducer as practicePatientsReducer } from './modules/practices/patients/v2reducer'
import { PracticesState, reducer as practiceReducer } from './modules/practices/reducer'
import { PracticesState as PracticesStateV2, reducer as PracticesReducerV2 } from './modules/practices/v2reducer'
import { reducer as referralsReducer, ReferralsState } from './modules/referrals/v2reducer'
import { reducer as uploadsReducer, UploadsState } from './modules/uploads/reducer'
import { reducer as YextReducerV2, YextState as YextStateV2 } from './modules/yext/v2reducer'
import { ReceiveFeatureFlags, ReceiveSelf, SetRedirect, SetSocketConnectionStatus } from './appActions'

export type Self = {
    account: AdminAccount
}

export type AppState = {
    self?: Self
    redirectTo?: string
    featureFlags?: ApiV2.FeatureFlags.FeatureFlags
    socketConnected: boolean
}

const initialState = (): AppState => ({ socketConnected: false })

type AppAction = ReceiveSelf | ReceiveFeatureFlags | SetRedirect | SetSocketConnectionStatus

export function appReducer(state: AppState = initialState(), action: AppAction): AppState {
    switch (action.type) {
        case 'RECEIVE_SELF': {
            return iassign(state, next => {
                next.self = {
                    account: new AdminAccount(action.account),
                }
                return next
            })
        }
        case 'RECEIVE_FEATURE_FLAGS': {
            return iassign(state, next => {
                next.featureFlags = action.featureFlags
                return next
            })
        }
        case 'SET_REDIRECT': {
            return iassign(state, next => {
                next.redirectTo = action.path
                return next
            })
        }
        case 'SET_SOCKET_CONNECTION_STATUS': {
            return iassign(
                state,
                next => next.socketConnected,
                () => action.connected,
            )
        }
        default:
            return state
    }
}

export type RootState = {
    admin: AdminState
    amplify: AmplifyState
    app: AppState
    navigation: NavigationState
    practices: PracticesState
    bookings: {
        directScheduling: DirectSchedulingState
        connect: ConnectState
    }
    dexVoice: DexVoiceState
    uploads: UploadsState
    chat: ChatState
    selfScheduling: SelfSchedulingState
    connectSurveys: ConnectSurveyState
    payments: PaymentsState
    notifications: NotificationsState
    practiceSurvey: PracticeSurveyState
    practiceLocations: PracticeLocationsState
    v2: {
        heartbeat: HeartbeatState
        practices: PracticesStateV2
        practiceSurveys: PracticeSurveyStateV2
        referrals: ReferralsState
        integrations: IntegrationsStateV2
        yext: YextStateV2
        practicePatients: PracticePatientsState
        availability: AvailabilityStateV2
        configLogs: ConfigLogsState
        reschedulingConfiguration: ReschedulingConfigurationState
    }
}

export default combineReducers({
    admin: adminReducer,
    amplify: amplifyReducer,
    app: appReducer,
    navigation: navigationReducer,
    practices: practiceReducer,
    bookings: combineReducers({
        directScheduling: directSchedulingReducer,
        connect: connectReducer,
    }),
    dexVoice: dexVoiceReducer,
    uploads: uploadsReducer,
    chat: chatReducer,
    selfScheduling: selfSchedulingReducer,
    connectSurveys: connectSurveysReducer,
    payments: paymentsReducer,
    notifications: notificationsReducer,
    practiceSurvey: practiceSurveyReducer,
    practiceLocations: practiceLocationsReducer,
    v2: combineReducers({
        practices: PracticesReducerV2,
        practiceSurveys: practiceSurveyReducerV2,
        referrals: referralsReducer,
        integrations: integrationsReducerV2,
        yext: YextReducerV2,
        practicePatients: practicePatientsReducer,
        availability: availabilityReducerV2,
        configLogs: configLogsReducer,
        reschedulingConfiguration: reschedulingConfigurationReducer,
    }),
})
