import { mapPracticeLocations } from '../../ApiV2/mappers/practice/practice'
import ApiService from '../service'

export default class PracticeService extends ApiService {
    // availabel includes[]:billing_address, integration_type, licenses, products
    private readonly PAGE_SIZE = 25
    private readonly PATIENTS_PAGE_SIZE = 10

    async getPractice(practiceId: string) {
        const practice = (await this.fetch(
            `practices/${practiceId}?include[]=integration_type`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeV2>

        return practice
    }

    async getPracticeLocationListPaginated(
        searchTerms: ApiV2.Practice.PracticeLocationSearchTerms,
        practiceId: string,
    ) {
        const { limit = 10, page = 1, sort = 'name', order = 'asc', query = '' } = searchTerms

        const locations = (await this.fetch(
            `practices/${practiceId}/practice-locations?include[]=heartbeat_agent_module_location&search=${query}&page=${page}&limit=${limit}&sort=${sort}&order=${order}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<Array<ApiV2.Practice.PracticeLocation>>

        // TO-DO-V2 remove this and refactor components accordignly
        return mapPracticeLocations(locations)
    }

    async getAllPracticeLocations(
        practiceId: string,
        page: number = 1,
    ): Promise<Array<ApiV2.Practice.PracticeLocationFull>> {
        const locations = (await this.fetch(
            `practices/${practiceId}/practice-locations?include[]=address&include[]=practice_specialty_type&include[]=survey&include[]=file_upload&include[]=practice_location_product&yext=true&page=${page}&limit=50`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<Array<ApiV2.Practice.PracticeLocationFull>>

        const allPages = Number(locations.metadata?.pagination_info?.allPages)
        const locationData = locations.data

        if (allPages > page) {
            return locationData.concat(await this.getAllPracticeLocations(practiceId, page + 1))
        } else {
            return locations.data
        }
    }

    async getPracticeLocations(practiceId: string, searchTerms: ApiV2.Practice.PracticeLocationSearchTerms) {
        const { page, limit, query } = searchTerms
        const { data: practiceLocations, metadata } = (await this.fetch(
            `practices/${practiceId}/practice-locations?sort=name&search=${query}&page=${page}&limit=${limit ||
                this.PAGE_SIZE}`,
            {
                mode: 'cors',
            },
        )) as ApiV2.StructuredResponse<Array<ApiV2.Practice.PracticeLocationFull>>

        return { data: practiceLocations, paginationInfo: metadata?.pagination_info }
    }

    async getPracticeLocationListV2(practiceId: string, page: number) {
        const locations = (await this.fetch(
            `practices/${practiceId}/practice-locations?include[]=heartbeat_agent_module_location&page=${page}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeLocationsV2[]>

        return locations
    }

    async getPracticeLocationsPaginated(
        practiceId: string,
        params: { search: string; page: number },
    ): Promise<ApiV2.StructuredResponse<Array<ApiV2.Practice.PracticeLocationFull>>> {
        const { search, page } = params
        const response = (await this.fetch(
            `practices/${practiceId}/practice-locations?include[]=address&include[]=practice_specialty_type&include[]=survey&include[]=practice_location_product&include[]=file_upload&yext=true&search=${search}&page=${page}&limit=8&sort=name&order=asc`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<Array<ApiV2.Practice.PracticeLocationFull>>

        return response
    }

    async getLocationVyneProducts(locationId: string) {
        const products = (await this.fetch(
            `practice-locations/${locationId}/vyne-products`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<string[]>

        return products
    }

    async postCreatePracticeLocation(practiceId: string, location: ApiV2.Practice.CreatePracticeLocation) {
        const createdLocation = (await this.fetch(
            `practices/${practiceId}/practice-locations?include[]=address&include[]=practice_specialty_type&include[]=practice_location_product`,
            {
                method: 'POST',
                body: JSON.stringify(location),
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.CreatePracticeLocationResponse>

        return createdLocation
    }

    async putUpdatePracticeLocation(practiceId: string, locationId: string, location: Api.UpdateLocation) {
        const updatedLocation = (await this.fetch(
            `practices/${practiceId}/practice-locations/${locationId}?include[]=address&include[]=practice_specialty_type`,
            {
                method: 'PUT',
                body: JSON.stringify(location),
            },
            undefined,
            undefined,
            true,
        )) as Api.PracticeLocation

        return updatedLocation
    }

    async getAllConnectedPracticeLocations(
        practiceId: string,
        page: number = 1,
        limit: number = 50,
    ): Promise<ModelsV2.Practice.ConnectedPracticeLocation[]> {
        const connectedLocations = (await this.fetch(
            `practices/${practiceId}/practice-locations/connected?page=${page}&limit=${limit}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.ConnectedPracticeLocation[]>

        const allPages = Number(connectedLocations.metadata?.pagination_info?.allPages)
        const locationData = connectedLocations.data

        if (allPages > page) {
            return locationData.concat(await this.getAllConnectedPracticeLocations(practiceId, page + 1))
        } else {
            return connectedLocations.data
        }
    }

    async getPracticeLocationSchedulingProcedures(
        practiceLocationId: string,
    ): Promise<ModelsV2.Practice.PracticeLocationSchedulingProcedure[]> {
        const { data: schedulingOperatories } = (await this.fetch(
            `practice-locations/${practiceLocationId}/scheduling-procedures`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeLocationSchedulingProcedure[]>

        return schedulingOperatories
    }

    async getPracticeLocationSchedulingOperatories(
        practiceLocationId: string,
    ): Promise<ModelsV2.Practice.PracticeLocationSchedulingOperatory[]> {
        const { data: schedulingOperatories } = (await this.fetch(
            `practice-locations/${practiceLocationId}/scheduling-operatories`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeLocationSchedulingOperatory[]>

        return schedulingOperatories
    }

    async getPracticeLocationSchedulingProviders(
        practiceLocationId: string,
    ): Promise<ModelsV2.Practice.PracticeLocationSchedulingProvider[]> {
        const { data: schedulingProviders } = (await this.fetch(
            `practice-locations/${practiceLocationId}/scheduling-providers`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeLocationSchedulingProvider[]>

        return schedulingProviders
    }

    async getPracticeLocationAvailabilities(
        practiceLocationId: string,
        procedureId: string,
        operatoryIds: string[],
        providerIds: string[],
        dateRange: { from: string; to: string },
    ): Promise<ModelsV2.Practice.PracticeLocationAvailabilities> {
        const operatory_ids = operatoryIds.join(',')
        const provider_ids = providerIds.join(',')
        const { data: schedulingProviders } = (await this.fetch(
            `practice-locations/${practiceLocationId}/availabilities?procedure_id=${procedureId}&operatory_ids=${operatory_ids}&provider_ids=${provider_ids}&start_date=${dateRange.from}&end_date=${dateRange.to}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeLocationAvailabilities>

        return schedulingProviders
    }

    async getLocationsByWebCode(webCodeId: string) {
        const locations = (await this.fetch(
            `amplify-web-codes/${webCodeId}/practice-locations`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.LocationByWebCode[]>

        return locations
    }

    async listArchivedChats(practiceId: string, searchTerms: ApiV2.Practice.ChatArchiveSearchTerms) {
        const structuredResponse = (await this.fetch(
            `practices/${practiceId}/amplify-chat-metadata/search`,
            {},
            {
                ...searchTerms,
            },
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.ChatMetadata[]>

        return structuredResponse
    }

    async getPracticePatientsFinancialData(practiceId: string) {
        const { data } = (await this.fetch(
            `practices/${practiceId}/patients/financial-data`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticePatientsFinancialData>

        return data
    }

    async searchPracticePatients(practiceId: string, searchTerms: ModelsV2.Practice.PracticePatientSearchTerms) {
        const { query = '', page = 1, sort = 'last_visit', order = 'desc' } = searchTerms

        const sortAndOrder = sort ? `&sort=${sort}&order=${order}` : ``

        const { data: practicePatients, metadata } = (await this.fetch(
            `practices/${practiceId}/amplify-referral-patients/search?search=${query}&page=${page}&limit=${this.PATIENTS_PAGE_SIZE}${sortAndOrder}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticePatientData[]>

        return { paginationInfo: metadata?.pagination_info, data: practicePatients }
    }

    async getPracticeStaffUsers(practiceId: string, searchTerms: ModelsV2.Practice.PracticeStaffSearchTerms) {
        const { query = '', limit = 8, page = 1, sort = 'last_name', order = 'asc' } = searchTerms
        const { data: staff, metadata } = (await this.fetch(
            `practices/${practiceId}/accounts`,
            {},
            {
                limit,
                page,
                search: query,
                sort,
                order,
            },
            undefined,
            undefined,
            ['account_status', 'simplifeye_role'],
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeStaffData[]>

        return { paginationInfo: metadata?.pagination_info, data: staff }
    }

    async getAllPracticeStaffUsers(
        practiceId: string,
        page: number = 1,
        limit: number = 50,
    ): Promise<ModelsV2.Practice.PracticeStaffData[]> {
        const staff = (await this.fetch(
            `practices/${practiceId}/accounts`,
            {},
            {
                limit,
                page,
            },
            undefined,
            undefined,
            ['account_status', 'simplifeye_role'],
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeStaffData[]>

        const allPages = Number(staff.metadata?.pagination_info?.allPages)
        const staffData = staff.data

        if (allPages > page) {
            return staffData.concat(await this.getAllPracticeStaffUsers(practiceId, page + 1))
        } else {
            return staff.data
        }
    }

    async listSchedulingLocations(
        practiceId: string,
        searchTerms: ModelsV2.Practice.PracticeSchedulingLocationsSearchTerms,
    ) {
        const { limit = 50, page = 1 } = searchTerms
        const { data: locations, metadata } = (await this.fetch(
            `practices/${practiceId}/heartbeat-agent-module-locations`,
            {},
            {
                limit,
                page,
            },
            undefined,
            undefined,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeSchedulingLocationsData[]>

        return { paginationInfo: metadata?.pagination_info, data: locations }
    }

    async listAllSchedulingLocations(
        practiceId: string,
        page: number = 1,
        limit: number = 50,
    ): Promise<ModelsV2.Practice.PracticeSchedulingLocationsData[]> {
        const locations = (await this.fetch(
            `practices/${practiceId}/heartbeat-agent-module-locations`,
            {},
            {
                limit,
                page,
            },
            undefined,
            undefined,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeSchedulingLocationsData[]>

        const allPages = Number(locations.metadata?.pagination_info?.allPages)
        const locationsData = locations.data

        if (allPages > page) {
            return locationsData.concat(await this.listAllSchedulingLocations(practiceId, page + 1))
        } else {
            return locations.data
        }
    }

    async listAllSchedulingLocationsWithHeartbeatAgentModule(
        practiceId: string,
        page: number = 1,
        limit: number = 50,
    ): Promise<ModelsV2.Practice.PracticeSchedulingLocationsDataWithHeartbeatAgentModule[]> {
        const locations = (await this.fetch(
            `practices/${practiceId}/heartbeat-agent-module-locations`,
            {},
            {
                limit,
                page,
            },
            undefined,
            undefined,
            ['heartbeat_agent_module'],
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeSchedulingLocationsDataWithHeartbeatAgentModule[]>

        const allPages = Number(locations.metadata?.pagination_info?.allPages)
        const locationsData = locations.data

        if (allPages > page) {
            return locationsData.concat(
                await this.listAllSchedulingLocationsWithHeartbeatAgentModule(practiceId, page + 1),
            )
        } else {
            return locations.data
        }
    }

    async getPracticeAccount(accountId: string): Promise<Api.StructuredResponse<ModelsV2.Practice.PracticeAccount>> {
        const structuredResponse = await this.fetch(
            `accounts/${accountId}?include[]=account_status&include[]=account_type&include[]=permission&include[]=simplifeye_role`,
        )

        return structuredResponse
    }

    async getAccountPracticeLocations(accountId: string, page: number) {
        const { data: practiceLocations, metadata } = (await this.fetch(
            `accounts/${accountId}/account-practice-locations?include[]=practice_location&page=${page}&limit=50`,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.AccountPracticeLocation[]>

        return { paginationInfo: metadata?.pagination_info, data: practiceLocations }
    }

    async getAllAccountPracticeLocations(
        accountId: string,
        page: number = 1,
    ): Promise<Array<ModelsV2.Practice.AccountPracticeLocation>> {
        const locations = (await this.fetch(
            `accounts/${accountId}/account-practice-locations?include[]=practice_location&page=${page}&limit=50`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<Array<ModelsV2.Practice.AccountPracticeLocation>>

        const allPages = Number(locations.metadata?.pagination_info?.allPages)
        const locationData = locations.data

        if (allPages > page) {
            return locationData.concat(await this.getAllAccountPracticeLocations(accountId, page + 1))
        } else {
            return locations.data
        }
    }

    async getPracticeLocationAccountsByLocationId(locationId: string, page: number = 1, limit: number = 8) {
        const { data: accounts, metadata } = (await this.fetch(
            `practice-locations/${locationId}/accounts?email_notifications=true&page=${page}&limit=${limit}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<Array<ModelsV2.Practice.PracticeSubscriberFromStaff>>

        return { paginationInfo: metadata?.pagination_info, data: accounts }
    }

    async getPracticeLocationSubscribersByLocationId(
        locationId: string,
        page: number = 1,
        limit: number = 8,
    ): Promise<Array<ModelsV2.Practice.PracticeLocationSubscriber>> {
        const { data: subscribers, metadata } = (await this.fetch(
            `practice-locations/${locationId}/amplify-subscribers?page=${page}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeLocationSubscriber[]>

        const allPages = Number(metadata?.pagination_info?.allPages)

        if (allPages > page) {
            return subscribers.concat(await this.getPracticeLocationSubscribersByLocationId(locationId, page + 1))
        } else {
            return subscribers
        }
    }

    async getSchedulingConfigurationLogs(
        practiceLocationId: string,
        searchParams: ModelsV2.Practice.PracticeLocationSchedulingConfigurationSearchParams,
    ) {
        const { start_date, end_date, user_ids, page = 1, limit = 5 } = searchParams

        const queryParams: any = {
            limit,
            page,
        }
        if (start_date) {
            queryParams.start_date = start_date
        }
        if (end_date) {
            queryParams.end_date = end_date
        }

        if (user_ids && user_ids?.length > 0 && user_ids[0] !== 'all') {
            queryParams['filter[account_id]'] = user_ids
        }

        const { data: schedulingProviders, metadata } = (await this.fetch(
            `practice-locations/${practiceLocationId}/scheduling-configuration-logs`,
            {
                method: 'GET',
            },
            queryParams,
            undefined,
            true,
            ['account'],
            undefined,
            true,
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeLocationSchedulingConfigurationLogs[]>

        return { paginationInfo: metadata?.pagination_info, data: schedulingProviders }
    }

    async getSchedulingConfigurationLogsCsv(
        practiceLocationId: string,
        searchParams: ModelsV2.Practice.PracticeLocationSchedulingConfigurationSearchParams,
    ) {
        const { start_date, end_date, user_ids, page = 1, limit = 5 } = searchParams

        const queryParams: any = {
            limit,
            page,
        }
        if (start_date) {
            queryParams.start_date = start_date
        }
        if (end_date) {
            queryParams.end_date = end_date
        }

        if (user_ids && user_ids?.length > 0) {
            queryParams['filter[account_id]'] = user_ids
        }

        return this.fetch(
            `practice-locations/${practiceLocationId}/scheduling-configuration-logs/csv`,
            {
                method: 'GET',
            },
            queryParams,
            undefined,
            true,
            ['account'],
            undefined,
            true,
        )
    }

    async getPracticeLocationReschedulingConfiguration(practiceId: string, page: number = 1, limit: number = 10) {
        const { data: reschedulingConfiguration } = (await this.fetch(
            `practices/${practiceId}/practice-location-rescheduling-configuration?page=${page}&limit=${limit}`,
            {
                method: 'GET',
            },
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeLocationReschedulingConfiguration[]>

        return reschedulingConfiguration
    }

    async putPracticeLocationReschedulingConfiguration(
        practiceId: string,
        configuration: ModelsV2.Practice.PracticeLocationReschedulingConfiguration[],
    ) {
        const { data: reschedulingConfiguration } = (await this.fetch(
            `practices/${practiceId}/practice-location-rescheduling-configuration`,
            {
                method: 'PUT',
                body: JSON.stringify(configuration),
            },
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeLocationReschedulingConfiguration[]>
        return reschedulingConfiguration
    }

    async getPracticeReschedulingConfiguration(practiceId: string) {
        const { data: reschedulingConfiguration } = (await this.fetch(
            `practices/${practiceId}/practice-rescheduling-configuration`,
            {
                method: 'GET',
            },
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeReschedulingConfiguration>

        return reschedulingConfiguration
    }

    async putPracticeReschedulingConfiguration(
        practiceId: string,
        configuration: ModelsV2.Practice.PracticeReschedulingConfiguration,
    ) {
        const { data: reschedulingConfiguration } = (await this.fetch(
            `practices/${practiceId}/practice-rescheduling-configuration`,
            {
                method: 'PUT',
                body: JSON.stringify(configuration),
            },
        )) as ApiV2.StructuredResponse<ModelsV2.Practice.PracticeReschedulingConfiguration>

        return reschedulingConfiguration
    }

    async postResetPassword(resetData: ApiV2.Practice.ResetPassword) {
        const data = await this.fetch(
            `accounts/password-reset`,
            {
                method: 'POST',
                body: JSON.stringify({ ...resetData }),
            },
            undefined,
            undefined,
            true,
        )

        return data
    }

    async postRestoreAccount(accountId: string) {
        const data = await this.fetch(`accounts/restore`, {
            method: 'POST',
            body: JSON.stringify({ accountId }),
        })

        return data
    }

    async putSaveAccount(accountId: string, account: ApiV2.Practice.AccountUpdate) {
        const { data } = await this.fetch(`accounts/${accountId}`, {
            method: 'PUT',
            body: JSON.stringify({ ...account }),
        })

        return data as Api.Account
    }

    async postCreateAccount(newAccount: ApiV2.Practice.AccountCreate) {
        const { data } = await this.fetch(`accounts`, {
            method: 'POST',
            body: JSON.stringify({
                ...newAccount,
            }),
        })

        return data as Api.Account
    }

    async postResendInvitation(invitation: ApiV2.Practice.InviteResend) {
        const response = await this.fetch(`accounts/invite/resend`, {
            method: 'POST',
            body: JSON.stringify({ ...invitation }),
        })

        return response
    }

    async putUpdatePracticeLocationProducts(practiceLocationId: string, products: ApiV2.Practice.ProductForSave[]) {
        const { data: productsResponse } = await this.fetch(`practice-locations/${practiceLocationId}/products`, {
            method: 'PUT',
            body: JSON.stringify({ products }),
        })

        return productsResponse
    }

    async postCreatePracticeLocationProduct(practiceLocationId: string, product: ApiV2.Practice.ProductForSave) {
        const response = await this.fetch(`practice-locations/${practiceLocationId}/products`, {
            method: 'POST',
            body: JSON.stringify({ product }),
        })

        return response
    }

    async postPracticeLocationsWritebackSettings(writebacks: ModelsV2.Practice.WritebackSetting[]) {
        const writebackSetting = (await this.fetch(`practice-locations/online-scheduling/writeback`, {
            method: 'POST',
            body: JSON.stringify({ writebacks }),
            mode: 'cors',
        })) as { statusCode: number }

        return writebackSetting.statusCode === 200 ? true : false
    }
}
