import ApiV2 from '../../ApiV2'
import { mapAccountPracticeLocations } from '../../ApiV2/mappers/v2/accounts'
import { AppDispatch, AppThunk } from '../../appStore'
import { HeartbeatPracticeLocationProvider, PaginationInfo, Practice, PracticeLocation } from '../../models/v2/Practice'

export enum PracticeActionTypes {
    SET_PRACTICE = '@V2/SET_PRACTICE',
    SET_RESET_PRACTICE_LOCATIONS_STATE = '@V2/SET_RESET_PRACTICE_LOCATIONS_STATE',
    RECEIVE_PRACTICE_LOCATIONS = '@V2/RECEIVE_PRACTICE_LOCATIONS',
    RECEIVE_PRACTICE_LOCATIONS_V2 = '@V2/RECEIVE_PRACTICE_LOCATIONS_V2',
    RECEIVE_PRACTICE_LOCATIONS_PAGINATED = '@V2/RECEIVE_PRACTICE_LOCATIONS_PAGINATED',
    RECEIVE_LOCATIONS_BY_WEBCODE = '@V2/RECEIVE_LOCATIONS_BY_WEBCODE',
    RECEIVE_HEARTBEAT_PRACTICE_LOCATION_PROVIDERS = '@V2/RECEIVE_HEARTBEAT_PRACTICE_LOCATION_PROVIDERS',
    RECEIVE_PRACTICE = '@V2/RECEIVE_PRACTICE',
    SET_RESET_LOCATIONS_STATE = '@V2/SET_RESET_LOCATIONS_STATE',
    RECEIVE_ARCHIVED_CHATS = '@V2/RECEIVE_ARCHIVED_CHATS',
    UPDATE_PENDING_REFERRAL_ON_CHAT = '@V2/UPDATE_PENDING_REFERRAL_ON_CHAT',
    RECEIVE_PRACTICE_STAFF_LIST = '@V2/RECEIVE_PRACTICE_STAFF_LIST',
    SEARCH_PRACTICE_STAFF_REQUEST = '@V2/SEARCH_PRACTICE_STAFF_REQUEST',
    SEARCH_PRACTICE_STAFF_SUCCESS = '@V2/SEARCH_PRACTICE_STAFF_SUCCESS',
    RESET_SEARCH_PRACTICE_STAFF = '@V2/RESET_SEARCH_PRACTICE_STAFF',
    RECEIVE_PRACTICE_ACCOUNT = '@V2/RECEIVE_PRACTICE_ACCOUNT',
    RECEIVE_USER_PHONE_NUMBER_BLACK_LIST = '@V2/RECEIVE_USER_PHONE_NUMBER_BLACK_LIST',
    RECEIVE_PRACTICE_ACCOUNT_LOCATIONS = '@V2/RECEIVE_PRACTICE_ACCOUNT_LOCATIONS',
    SEARCH_PRACTICE_LOCATION_ACCOUNTS_REQUEST = '@V2/SEARCH_PRACTICE_LOCATION_ACCOUNTS_REQUEST',
    RECEIVE_PRACTICE_LOCATION_ACCOUNTS = '@V2/RECEIVE_PRACTICE_LOCATION_ACCOUNTS',
    SEARCH_PRACTICE_LOCATION_SUBSCRIBERS_REQUEST = '@V2/SEARCH_PRACTICE_LOCATION_SUBSCRIBERS_REQUEST',
    RECEIVE_PRACTICE_LOCATION_SUBSCRIBERS = '@V2/RECEIVE_PRACTICE_LOCATION_SUBSCRIBERS',
    SET_NOTIFICATIONS_SELECTED_LOCATION_ID = '@V2/SET_NOTIFICATIONS_SELECTED_LOCATION_ID',
    RECEIVE_WRITEBACK_SETTINGS = '@V2/RECEIVE_WRITEBACK_SETTINGS',
}

export type UpdatePendingReferralOnChat = {
    type: PracticeActionTypes.UPDATE_PENDING_REFERRAL_ON_CHAT
    chatId: string
}

export type ReceiveArchivedChats = {
    type: PracticeActionTypes.RECEIVE_ARCHIVED_CHATS
    practiceId: string
    chats: ModelsV2.Practice.ChatMetadata[]
    paginationInfo?: Api.PaginationInfo
}

export type SetPractice = {
    type: PracticeActionTypes.SET_PRACTICE
    practice: Practice
}

export type ReceivePractice = {
    type: PracticeActionTypes.RECEIVE_PRACTICE
    practiceId: string
    practice: ModelsV2.Practice.PracticeV2
}

export type SetResetPracticeLocationState = {
    type: PracticeActionTypes.SET_RESET_PRACTICE_LOCATIONS_STATE
    practiceId: string
}
export type SetResetLocationState = {
    type: PracticeActionTypes.SET_RESET_LOCATIONS_STATE
    practiceId: string
}

export type ReceivePracticeLocationsV2 = {
    type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATIONS_V2
    practiceId: string
    locations: ModelsV2.Practice.PracticeLocationsV2[]
}

export type ReceiveLocationsByWebCode = {
    type: PracticeActionTypes.RECEIVE_LOCATIONS_BY_WEBCODE
    webCodeId: string
    locations: ModelsV2.Practice.LocationByWebCode[]
}

export type ReceivePracticeAccount = {
    type: PracticeActionTypes.RECEIVE_PRACTICE_ACCOUNT
    practiceId: string
    account: ModelsV2.Practice.PracticeAccount
}

export type ReceivePracticeAccountLocations = {
    type: PracticeActionTypes.RECEIVE_PRACTICE_ACCOUNT_LOCATIONS
    practiceId: string
    accountId: string
    accountPracticeLocations: ModelsV2.Practice.AccountPracticeLocation[]
}

export type SearchPracticeLocationAccountsRequest = {
    type: PracticeActionTypes.SEARCH_PRACTICE_LOCATION_ACCOUNTS_REQUEST
    practiceId: string
    locationId: string
    page: number
    loading: boolean
}

export type ReceivePracticeLocationAccounts = {
    type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATION_ACCOUNTS
    practiceId: string
    locationId: string
    practiceLocationAccounts: ModelsV2.Practice.PracticeSubscriberFromStaff[]
    pagination: Models.PaginationInfo | undefined
}

export type ReceiveWritebackSettings = {
    type: PracticeActionTypes.RECEIVE_WRITEBACK_SETTINGS
    practiceId: string
    writebacks: ModelsV2.Practice.WritebackSetting[]
}

export type SearchPracticeLocationSubscribersRequest = {
    type: PracticeActionTypes.SEARCH_PRACTICE_LOCATION_SUBSCRIBERS_REQUEST
    practiceId: string
    searchTerms: ModelsV2.Practice.PracticeLocationSubscribersSearchTerms
    loading: boolean
}

export type ReceivePracticeLocationSubscribers = {
    type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATION_SUBSCRIBERS
    practiceId: string
    locationId: string
    practiceLocationSubscribers: ModelsV2.Practice.PracticeLocationSubscriber[]
}

export type ReceivePracticeLocations = {
    type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATIONS
    practice: Practice
    locations: Array<PracticeLocation>
    paginationInfo?: PaginationInfo
    searchTerms?: ApiV2.Practice.PracticeLocationSearchTerms
}

export type ReceivePracticeLocationsPaginated = {
    type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATIONS_PAGINATED
    practice: Practice
    locations: Array<PracticeLocation>
    paginationInfo?: PaginationInfo
    searchTerms?: ApiV2.Practice.PracticeLocationSearchTerms
}

export type ReceiveHeartbeatPracticeLocationProviders = {
    type: PracticeActionTypes.RECEIVE_HEARTBEAT_PRACTICE_LOCATION_PROVIDERS
    practice: Practice
    locationProviders: Array<HeartbeatPracticeLocationProvider>
}

export type SearchPracticeStaffRequest = {
    type: PracticeActionTypes.SEARCH_PRACTICE_STAFF_REQUEST
    practiceId: string
    searchTerms: ModelsV2.Practice.PracticeStaffSearchTerms
    loading: boolean
}

export type SearchPracticeStaffSuccess = {
    type: PracticeActionTypes.SEARCH_PRACTICE_STAFF_SUCCESS
    practiceId: string
    staff: ModelsV2.Practice.PracticeStaffData[]
    pagination: Models.PaginationInfo | undefined
}

export type ResetSearchPracticeStaff = {
    type: PracticeActionTypes.RESET_SEARCH_PRACTICE_STAFF
    practiceId: string
}

export type ReceivePracticeStaffList = {
    type: PracticeActionTypes.RECEIVE_PRACTICE_STAFF_LIST
    practiceId: string
    staff: Array<Api.Account>
    paginationInfo?: Models.PaginationInfo
    page?: number
    sort?: ModelsV2.Practice.StaffSort
    order?: ModelsV2.Practice.StaffSortOrdering
}

export type ReceiveUserPhoneNumberBlackList = {
    type: PracticeActionTypes.RECEIVE_USER_PHONE_NUMBER_BLACK_LIST
    practiceId: string
    accountId: string
    phoneNumberBlackList: string[]
}

export type SetNotificationsSelectedLocationId = {
    type: PracticeActionTypes.SET_NOTIFICATIONS_SELECTED_LOCATION_ID
    practiceId: string
    locationId: string
}

export function receiveUpdatePendingReferralOnChat(chatId: string): UpdatePendingReferralOnChat {
    return {
        type: PracticeActionTypes.UPDATE_PENDING_REFERRAL_ON_CHAT,
        chatId,
    }
}

export function receiveArchivedChats(
    practiceId: string,
    chats: ModelsV2.Practice.ChatMetadata[],
    paginationInfo?: Api.PaginationInfo,
): ReceiveArchivedChats {
    return {
        type: PracticeActionTypes.RECEIVE_ARCHIVED_CHATS,
        practiceId,
        chats,
        paginationInfo,
    }
}

export function receivePracticeLocations(
    practice: Practice,
    locations: Array<PracticeLocation>,
    paginationInfo?: PaginationInfo,
    searchTerms?: ApiV2.Practice.PracticeLocationSearchTerms,
): ReceivePracticeLocations {
    return {
        type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATIONS,
        practice,
        locations,
        paginationInfo,
        searchTerms,
    }
}

export function receivePracticeLocationsPaginated(
    practice: Practice,
    locations: Array<PracticeLocation>,
    paginationInfo?: PaginationInfo,
    searchTerms?: ApiV2.Practice.PracticeLocationSearchTerms,
): ReceivePracticeLocationsPaginated {
    return {
        type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATIONS_PAGINATED,
        practice,
        locations,
        paginationInfo,
        searchTerms,
    }
}

export function receivePracticeLocationsV2(
    practiceId: string,
    locations: ModelsV2.Practice.PracticeLocationsV2[],
): ReceivePracticeLocationsV2 {
    return {
        type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATIONS_V2,
        practiceId,
        locations,
    }
}

export function receiveLocationsByWebcode(
    webCodeId: string,
    locations: ModelsV2.Practice.LocationByWebCode[],
): ReceiveLocationsByWebCode {
    return {
        type: PracticeActionTypes.RECEIVE_LOCATIONS_BY_WEBCODE,
        webCodeId,
        locations,
    }
}

export function receivePracticeAccount(
    practiceId: string,
    account: ModelsV2.Practice.PracticeAccount,
): ReceivePracticeAccount {
    return {
        type: PracticeActionTypes.RECEIVE_PRACTICE_ACCOUNT,
        practiceId,
        account,
    }
}

export function receivePracticeAccountLocations(
    practiceId: string,
    accountId: string,
    accountPracticeLocations: ModelsV2.Practice.AccountPracticeLocation[],
): ReceivePracticeAccountLocations {
    return {
        type: PracticeActionTypes.RECEIVE_PRACTICE_ACCOUNT_LOCATIONS,
        practiceId,
        accountId,
        accountPracticeLocations,
    }
}

export function searchPracticeLocationAccountsRequest(
    practiceId: string,
    locationId: string,
    page: number,
    loading: boolean = true,
): SearchPracticeLocationAccountsRequest {
    return {
        type: PracticeActionTypes.SEARCH_PRACTICE_LOCATION_ACCOUNTS_REQUEST,
        practiceId,
        locationId,
        page,
        loading,
    }
}

export function receivePracticeLocationAccounts(
    practiceId: string,
    locationId: string,
    practiceLocationAccounts: ModelsV2.Practice.PracticeSubscriberFromStaff[],
    pagination: Models.PaginationInfo | undefined,
): ReceivePracticeLocationAccounts {
    return {
        type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATION_ACCOUNTS,
        practiceId,
        locationId,
        practiceLocationAccounts,
        pagination,
    }
}

export function receiveWritebackSettings(
    practiceId: string,
    writebacks: ModelsV2.Practice.WritebackSetting[],
): ReceiveWritebackSettings {
    return {
        type: PracticeActionTypes.RECEIVE_WRITEBACK_SETTINGS,
        practiceId,
        writebacks,
    }
}

export function searchPracticeLocationSubscribersRequest(
    practiceId: string,
    searchTerms: ModelsV2.Practice.PracticeLocationSubscribersSearchTerms,
    loading: boolean = true,
): SearchPracticeLocationSubscribersRequest {
    return {
        type: PracticeActionTypes.SEARCH_PRACTICE_LOCATION_SUBSCRIBERS_REQUEST,
        practiceId,
        searchTerms,
        loading,
    }
}

export function receivePracticeLocationSubscribers(
    practiceId: string,
    locationId: string,
    practiceLocationSubscribers: ModelsV2.Practice.PracticeLocationSubscriber[],
): ReceivePracticeLocationSubscribers {
    return {
        type: PracticeActionTypes.RECEIVE_PRACTICE_LOCATION_SUBSCRIBERS,
        practiceId,
        locationId,
        practiceLocationSubscribers,
    }
}

export function receivePractice(practiceId: string, practice: ModelsV2.Practice.PracticeV2): ReceivePractice {
    return {
        type: PracticeActionTypes.RECEIVE_PRACTICE,
        practiceId,
        practice,
    }
}

export function receiveHearthbeatPracticeLocationProviders(
    practice: Practice,
    locationProviders: Array<HeartbeatPracticeLocationProvider>,
): ReceiveHeartbeatPracticeLocationProviders {
    return {
        type: PracticeActionTypes.RECEIVE_HEARTBEAT_PRACTICE_LOCATION_PROVIDERS,
        practice,
        locationProviders,
    }
}

// add practice to v2 state so we can save locations and other parts
export function setPractice(practice: Practice): SetPractice {
    return {
        type: PracticeActionTypes.SET_PRACTICE,
        practice,
    }
}

export function setResetPracticeLocationState(practiceId: string): SetResetPracticeLocationState {
    return {
        type: PracticeActionTypes.SET_RESET_PRACTICE_LOCATIONS_STATE,
        practiceId: practiceId,
    }
}
export function setResetLocationsState(practiceId: string): SetResetLocationState {
    return {
        type: PracticeActionTypes.SET_RESET_LOCATIONS_STATE,
        practiceId: practiceId,
    }
}

export function receivePracticeStaffList(
    practiceId: string,
    query: string,
    staff: Array<Api.Account>,
    paginationInfo?: Models.PaginationInfo,
    page?: number,
    sort?: ModelsV2.Practice.StaffSort,
    order?: ModelsV2.Practice.StaffSortOrdering,
): ReceivePracticeStaffList {
    return {
        type: PracticeActionTypes.RECEIVE_PRACTICE_STAFF_LIST,
        practiceId,
        staff,
        paginationInfo,
        page,
        sort,
        order,
    }
}

export function searchPracticeStaffRequest(
    practiceId: string,
    searchTerms: ModelsV2.Practice.PracticeStaffSearchTerms,
    loading: boolean = true,
): SearchPracticeStaffRequest {
    return {
        type: PracticeActionTypes.SEARCH_PRACTICE_STAFF_REQUEST,
        practiceId,
        searchTerms,
        loading,
    }
}

export function searchPracticeStaffSuccess(
    practiceId: string,
    staff: ModelsV2.Practice.PracticeStaffData[],
    pagination: Models.PaginationInfo | undefined,
): SearchPracticeStaffSuccess {
    return {
        type: PracticeActionTypes.SEARCH_PRACTICE_STAFF_SUCCESS,
        practiceId,
        staff,
        pagination,
    }
}

export function resetSearchPracticeStaff(practiceId: string): ResetSearchPracticeStaff {
    return {
        type: PracticeActionTypes.RESET_SEARCH_PRACTICE_STAFF,
        practiceId,
    }
}

export function receiveUserPhoneNumberBlackList(
    practiceId: string,
    accountId: string,
    phoneNumberBlackList: string[],
): ReceiveUserPhoneNumberBlackList {
    return {
        type: PracticeActionTypes.RECEIVE_USER_PHONE_NUMBER_BLACK_LIST,
        practiceId,
        accountId,
        phoneNumberBlackList,
    }
}

export function setNotificationsSelectedLocationId(
    practiceId: string,
    locationId: string,
): SetNotificationsSelectedLocationId {
    return {
        type: PracticeActionTypes.SET_NOTIFICATIONS_SELECTED_LOCATION_ID,
        practiceId,
        locationId,
    }
}

export function updatePendingReferralOnChat(chatId: string) {
    return async (dispatch: any) => {
        await dispatch(receiveUpdatePendingReferralOnChat(chatId))
    }
}

export function fetchPractice(practiceId: string): AppThunk<Promise<ModelsV2.Practice.PracticeV2>> {
    return async (dispatch: AppDispatch) => {
        const { data: practice } = await ApiV2.Practice.getPractice(practiceId)
        await dispatch(receivePractice(practiceId, practice))
        return practice
    }
}

export function fetchArchivedChats(practiceId: string, searchTerms: ApiV2.Practice.ChatArchiveSearchTerms) {
    return async (dispatch: any) => {
        const { data: chats, metadata } = await ApiV2.Practice.listArchivedChats(practiceId, searchTerms)

        const pagination_info = metadata?.pagination_info

        await dispatch(receiveArchivedChats(practiceId, chats, pagination_info))
    }
}

//TO-DO-V2 deprecated - not good - remove this when finish referrals refactor
export function fetchPracticeLocations(searchTerms: ApiV2.Practice.PracticeLocationSearchTerms, practice: Practice) {
    return async (dispatch: any) => {
        const { data: locations, metadata } = await ApiV2.Practice.getPracticeLocationListPaginated(
            searchTerms,
            practice.id,
        )

        await dispatch(receivePracticeLocations(practice, locations, metadata?.pagination_info, searchTerms))
        return locations
    }
}

export function fetchPracticeLocationV2s(
    practiceId: string,
    page: number,
): AppThunk<Promise<{ pagination_info?: ApiV2.PaginationInfo | undefined } | undefined>> {
    return async (dispatch: AppDispatch) => {
        const { data: locations, metadata } = await ApiV2.Practice.getPracticeLocationListV2(practiceId, page)
        await dispatch(receivePracticeLocationsV2(practiceId, locations))
        return metadata
    }
}

export function fetchPracticeLocationsPaginated(
    searchTerms: ApiV2.Practice.PracticeLocationSearchTerms,
    practice: Practice,
) {
    return async (dispatch: any) => {
        const { data: locations, metadata } = await ApiV2.Practice.getPracticeLocationListPaginated(
            searchTerms,
            practice.id,
        )

        await dispatch(receivePracticeLocationsPaginated(practice, locations, metadata?.pagination_info, searchTerms))
        return locations
    }
}

export function fetchLocationsByWebcode(webCodeId: string): AppThunk<Promise<ModelsV2.Practice.LocationByWebCode[]>> {
    return async (dispatch: AppDispatch) => {
        const { data: locations } = await ApiV2.Practice.getLocationsByWebCode(webCodeId)
        await dispatch(receiveLocationsByWebcode(webCodeId, locations))
        return locations
    }
}

export function searchPracticeStaffUsers(
    practiceId: string,
    searchTerms: ModelsV2.Practice.PracticeStaffSearchTerms,
    loading: boolean = true,
): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch) => {
        dispatch(searchPracticeStaffRequest(practiceId, searchTerms, loading))
        const { data: staff, paginationInfo } = await ApiV2.Practice.getPracticeStaffUsers(practiceId, searchTerms)
        await dispatch(searchPracticeStaffSuccess(practiceId, staff, paginationInfo))
        return staff
    }
}

export function restoreAccount(practice: Models.Practice, account: ModelsV2.Practice.PracticeStaffData): any {
    return async (dispatch: any) => {
        const restored = await ApiV2.Practice.postRestoreAccount(account.id)
        if (restored.success) {
            const { data: accountUpdated } = await ApiV2.Practice.getPracticeAccount(account.id)
            await dispatch(receivePracticeAccount(practice.id, accountUpdated))
        }
    }
}

export function fetchPracticeAccount(practiceId: string, accountId: string): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch) => {
        const { data: account } = await ApiV2.Practice.getPracticeAccount(accountId)
        await dispatch(receivePracticeAccount(practiceId, account))
    }
}

export function fetchPracticeAccountLocations(
    practiceId: string,
    accountId: string,
    page: number,
): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch) => {
        const accountPracticeLocations = await ApiV2.Practice.getAllAccountPracticeLocations(accountId, page)

        await dispatch(
            receivePracticeAccountLocations(
                practiceId,
                accountId,
                mapAccountPracticeLocations(accountPracticeLocations),
            ),
        )
    }
}

export function fetchPracticeLocationAccounts(
    practiceId: string,
    locationId: string,
    page: number,
    loading: boolean = true,
): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch) => {
        dispatch(searchPracticeLocationAccountsRequest(practiceId, locationId, page, loading))
        const {
            data: practiceLocationAccounts,
            paginationInfo,
        } = await ApiV2.Practice.getPracticeLocationAccountsByLocationId(locationId, page)
        await dispatch(
            receivePracticeLocationAccounts(practiceId, locationId, practiceLocationAccounts, paginationInfo),
        )
    }
}

export function fetchPracticeLocationSubscribers(
    practiceId: string,
    searchTerms: ModelsV2.Practice.PracticeLocationSubscribersSearchTerms,
    loading: boolean = true,
): AppThunk<Promise<any>> {
    return async (dispatch: AppDispatch) => {
        dispatch(searchPracticeLocationSubscribersRequest(practiceId, searchTerms, loading))
        const practiceLocationSubscribers = await ApiV2.Practice.getPracticeLocationSubscribersByLocationId(
            searchTerms.locationId,
            searchTerms.page,
        )
        await dispatch(
            receivePracticeLocationSubscribers(practiceId, searchTerms.locationId, practiceLocationSubscribers),
        )
    }
}

export function setPracticeLocationsWritebackSettings(
    practiceId: string,
    writebacks: ModelsV2.Practice.WritebackSetting[],
): any {
    return async (dispatch: AppDispatch) => {
        try {
            const success = await ApiV2.Practice.postPracticeLocationsWritebackSettings(writebacks)
            dispatch(receiveWritebackSettings(practiceId, writebacks))
            return success
        } catch (error) {
            console.log(error)
        }
    }
}
