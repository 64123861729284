import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@mui/material'
import moment from 'moment'

import { PracticeLocationAvailabilities } from '../../../models/v2/Practice'

import { END_HOUR, hourSlots, minuteSlots, momentByTime, START_HOUR, timeSlots, TimeSlotState } from './timeSlotFactory'

import './AvailabilityWeeklyView.sass'

type Data = PracticeLocationAvailabilities

interface Slot {
    [key: string]: string | undefined
}

export type SchedulerProps = {
    data: Data
    isPending: boolean
    setDateRange: (dateRange: DaysRange) => void
}

interface DaysRange {
    from: string
    to: string
}
type Props = SchedulerProps

const API_DATE_FORMAT = 'YYYY-MM-DD'
const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY'
const HOUR_SLOT_HEIGHT = 40
const SCHEDULER_HEIGHT = (END_HOUR - START_HOUR + 1) * HOUR_SLOT_HEIGHT
const SCHEDULER_OFFSET = (START_HOUR - hourSlots[0]) * HOUR_SLOT_HEIGHT
const INITIAL_DATE_RANGE: DaysRange = {
    from: moment().format(API_DATE_FORMAT),
    to: moment()
        .add(6, 'days')
        .format(API_DATE_FORMAT),
}

const AvailabilityWeeklyView = (props: Props) => {
    const [slots, setSlots] = useState<Slot[]>([])
    const [headerOffset, setHeaderOffset] = useState(0)
    const [timeOffset, setTimeOffset] = useState<number>(SCHEDULER_OFFSET)
    const [daysRange, setDaysRange] = useState<DaysRange>(INITIAL_DATE_RANGE)
    const [markToday, setMarkToday] = useState(false)

    const container = useRef<HTMLDivElement>(null)
    const table = useRef<HTMLDivElement>(null)

    const { data, isPending } = props

    useEffect(() => {
        addContainerScrollListener()
        if (container.current) {
            container.current.scrollTop = timeOffset
        }
        calculateOffsets()
    }, [])

    useEffect(() => {
        setSlots(makeSlots(props.data || [], daysRange.from, daysRange.to))
    }, [props.data, daysRange.from, daysRange.to])

    const hasData = () => {
        const { data } = props
        return Boolean(data)
    }

    const hasScrollBar = () => {
        if (container.current) {
            return Boolean(container.current.offsetWidth - container.current.clientWidth)
        }
        return false
    }

    const addContainerScrollListener = () => {
        if (container.current) {
            container.current.addEventListener('scroll', calculateOffsets)
        }
    }

    const calculateOffsets = () => {
        if (container.current) {
            setHeaderOffset(container.current.scrollLeft)
            setTimeOffset(container.current.scrollTop)
        } else {
            setHeaderOffset(0)
            setTimeOffset(SCHEDULER_OFFSET)
        }
    }

    const makeSlots = (data: Data, daysRangeFrom: string, daysRangeTo: string): Slot[] => {
        const daysBetweenDates = getDaysBetweenDates(moment(daysRangeFrom), moment(daysRangeTo))

        return daysBetweenDates.map(day => {
            const dayFormatted = moment(day, DISPLAY_DATE_FORMAT).format(API_DATE_FORMAT)
            const slotsMap: Slot = timeSlots().reduce((slots: Slot, time: moment.Moment) => {
                return {
                    ...slots,
                    [time.format('H:m')]: '',
                }
            }, {})

            const slotKeys = Object.keys(slotsMap)

            if (data[dayFormatted]) {
                data[dayFormatted].forEach(reservation => {
                    const rangeStart = moment(reservation.start, 'HH:mm:ss').format('H:m')
                    const rangeEnd = moment(reservation.end, 'HH:mm:ss').format('H:m')
                    for (let index = slotKeys.indexOf(rangeStart); index < slotKeys.indexOf(rangeEnd); index += 1) {
                        slotsMap[slotKeys[index]] = dayFormatted
                    }
                })
            } else {
                for (let index = 0; index < slotKeys.length; index += 1) {
                    slotsMap[slotKeys[index]] = undefined
                }
            }

            return slotsMap
        })
    }

    const renderHeaderName = (column: any) => {
        return column
    }

    const getDaysBetweenDates = function(startDate: moment.Moment, endDate: moment.Moment) {
        var now = startDate.clone(),
            dates = []

        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format(DISPLAY_DATE_FORMAT))
            now.add(1, 'days')
        }
        return dates
    }

    const getEmptyDaysBetweenDates = function(startDate: moment.Moment, endDate: moment.Moment) {
        var now = startDate.clone(),
            dates = []

        while (now.isSameOrBefore(endDate)) {
            dates.push(now.format(API_DATE_FORMAT))
            now.add(1, 'days')
        }

        return dates
    }

    const selectToday = () => {
        setDaysRange(INITIAL_DATE_RANGE)
        setMarkToday(true)
    }

    const moveToPreviousWeek = () => {
        const newDateRange = {
            from: moment(daysRange.from)
                .subtract(7, 'days')
                .format(API_DATE_FORMAT),
            to: moment(daysRange.to)
                .subtract(7, 'days')
                .format(API_DATE_FORMAT),
        }
        setDaysRange(newDateRange)
        props.setDateRange(newDateRange)
    }

    const moveToNextWeek = () => {
        const newDateRange = {
            from: moment(daysRange.from)
                .add(7, 'days')
                .format(API_DATE_FORMAT),
            to: moment(daysRange.to)
                .add(7, 'days')
                .format(API_DATE_FORMAT),
        }
        setDaysRange(newDateRange)
        props.setDateRange(newDateRange)
    }

    const getMonthName = () => {
        const fromMonthName = moment(daysRange.from).format('MMMM')
        const toMonthName = moment(daysRange.to).format('MMMM')
        const yearFrom = moment(daysRange.from).format('YYYY')
        const yearTo = moment(daysRange.to).format('YYYY')
        if (fromMonthName === toMonthName) {
            return `${toMonthName} ${yearTo}`
        }

        if (yearFrom === yearTo) {
            return `${fromMonthName}/${toMonthName} ${yearTo}`
        }

        return `${fromMonthName} ${yearFrom}/${toMonthName} ${yearTo}`
    }

    const renderMonthNameHeader = () => {
        return (
            <>
                {hasData() && (
                    <div className="availability-weekly-view__today-button-wrapper">
                        <Button
                            className="availability-weekly-view__today-button"
                            color="primary"
                            variant="outlined"
                            onClick={selectToday}
                        >
                            Today
                        </Button>
                    </div>
                )}
                <div className="availability-weekly-view__month-name">{getMonthName()}</div>
            </>
        )
    }
    const renderHeader = () => {
        return (
            <div className="availability-weekly-view__row-header availability-weekly-view__row--header">
                {hasData() ? (
                    <div
                        className="availability-weekly-view__cell availability-weekly-view__cell--header availability-weekly-view__cell--back"
                        onClick={moveToPreviousWeek}
                    >
                        <i className="material-icons availability-weekly-view__back-arrow">keyboard_arrow_left</i>
                    </div>
                ) : (
                    <div className="availability-weekly-view__cell availability-weekly-view__cell--header availability-weekly-view__cell--back-disabled"></div>
                )}
                <div
                    className="availability-weekly-view__row-container"
                    style={{ transform: `translateX(${-headerOffset}px)` }}
                >
                    {getDaysBetweenDates(moment(daysRange.from), moment(daysRange.to)).map(column => (
                        <div
                            key={column}
                            className={`availability-weekly-view__cell availability-weekly-view__cell--header ${
                                markToday && column === moment().format(DISPLAY_DATE_FORMAT)
                                    ? `availability-weekly-view__cell--today`
                                    : ``
                            }`}
                        >
                            <div className="availability-weekly-view__cell-label">
                                <div className="availability-weekly-view__cell-label-first-row">
                                    {moment(column, 'MM/DD/YYYY').format('dddd')}
                                </div>
                                <div className="availability-weekly-view__cell-label-second-row">
                                    {renderHeaderName(column)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {hasData() ? (
                    <div
                        className="availability-weekly-view__cell availability-weekly-view__cell--header availability-weekly-view__cell--back"
                        onClick={moveToNextWeek}
                    >
                        <i className="material-icons availability-weekly-view__back-arrow">keyboard_arrow_right</i>
                    </div>
                ) : (
                    <div className="availability-weekly-view__cell availability-weekly-view__cell--header availability-weekly-view__cell--back-disabled"></div>
                )}
            </div>
        )
    }

    const renderBody = (noData: boolean = false) => {
        const daysBetweenDatesFormatted = getDaysBetweenDates(moment(daysRange.from), moment(daysRange.to)).map(day =>
            moment(day, DISPLAY_DATE_FORMAT).format(API_DATE_FORMAT),
        )

        return hourSlots.map((time, hourIndex) => (
            <div key={time} className={`availability-weekly-view__row${hasScrollBar() ? `` : `-no-scroll-bar`}`}>
                {!noData ? (
                    <>
                        {daysBetweenDatesFormatted.map((day, index) => (
                            <div key={day} className="availability-weekly-view__cell">
                                {renderCell(hourIndex, index)}
                            </div>
                        ))}
                        {renderEmptyCell()}
                    </>
                ) : (
                    <>
                        {getEmptyDaysBetweenDates(moment(daysRange.from), moment(daysRange.to)).map((day, index) => (
                            <div key={day} className="availability-weekly-view__cell">
                                {renderCell(hourIndex, index)}
                            </div>
                        ))}
                        {renderEmptyCell()}
                    </>
                )}
            </div>
        ))
    }

    const renderCell = (hourIndex: number, sourceIndex: number) => {
        const keys = slots[sourceIndex] ? Object.keys(slots[sourceIndex]) : []
        const hour = hourSlots[hourIndex]

        return (
            <React.Fragment>
                {minuteSlots.map((minute: number) => {
                    if (!slots[sourceIndex]) {
                        return null
                    }

                    const key = `${hour}:${minute}`

                    if (slots[sourceIndex][key] === undefined) {
                        return null
                    }

                    const rangeId = slots[sourceIndex][key]
                    const slotIndex = keys.indexOf(key)
                    const classNames: string[] = [
                        'availability-weekly-view__slot',
                        `availability-weekly-view__slot--${
                            slots[sourceIndex][key] ? TimeSlotState.AVAILABLE : TimeSlotState.UNAVAILABLE
                        }`,
                    ]

                    if (slotIndex === 0 || slots[sourceIndex][keys[slotIndex - 1]] !== rangeId) {
                        classNames.push('availability-weekly-view__slot--starting')
                    }

                    if (slotIndex === keys.length - 1 || slots[sourceIndex][keys[slotIndex + 1]] !== rangeId) {
                        classNames.push('availability-weekly-view__slot--ending')
                    }

                    return (
                        <div key={`${sourceIndex.toString()}-${key}`} className={classNames.join(' ')}>
                            {' '}
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }

    const renderEmptyCell = () => {
        return <div className="availability-weekly-view__cell availability-weekly-view__cell--empty"> </div>
    }

    const renderTimeSlots = () => {
        return (
            <React.Fragment>
                {hourSlots.map(time => (
                    <div key={time} className="availability-weekly-view__time-slot">
                        {momentByTime(time).format('h a')}
                    </div>
                ))}
            </React.Fragment>
        )
    }

    const schedulerHeightStyle = { height: `${SCHEDULER_HEIGHT}px` }
    return (
        <div className="availability-weekly-view">
            {hasData() && !isPending ? (
                <>
                    <div className="availability-weekly-view__month-name-header">{renderMonthNameHeader()}</div>
                    <div className="availability-weekly-view__header">{renderHeader()}</div>
                    <div ref={table} className="availability-weekly-view__table" style={schedulerHeightStyle}>
                        <div className="availability-weekly-view__time-slots" style={schedulerHeightStyle}>
                            <div
                                className="availability-weekly-view__time-wrapper"
                                style={{
                                    transform: `translateY(${-timeOffset}px)`,
                                }}
                            >
                                {renderTimeSlots()}
                            </div>
                        </div>
                        <div ref={container} className="availability-weekly-view__body" style={schedulerHeightStyle}>
                            {renderBody()}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="availability-weekly-view__month-name-header availability-weekly-view--disabled">
                        {renderMonthNameHeader()}
                    </div>
                    <div className="availability-weekly-view__header availability-weekly-view--disabled">
                        {renderHeader()}
                    </div>
                    <div
                        ref={table}
                        className="availability-weekly-view__table availability-weekly-view--disabled"
                        style={schedulerHeightStyle}
                    >
                        <div className="availability-weekly-view__time-slots" style={schedulerHeightStyle}>
                            <div
                                className="availability-weekly-view__time-wrapper"
                                style={{
                                    transform: `translateY(${-timeOffset}px)`,
                                }}
                            >
                                {renderTimeSlots()}
                            </div>
                        </div>
                        <div
                            ref={container}
                            className="availability-weekly-view__body availability-weekly-view--disabled"
                            style={schedulerHeightStyle}
                        >
                            {renderBody(true)}
                        </div>
                    </div>
                    {!isPending && !hasData() && (
                        <div className="availability-weekly-view__no-data">
                            <div className="availability-weekly-view__no-data-icon">
                                <i className="material-icons">info</i>
                            </div>
                            <div className="availability-weekly-view__no-data-message">
                                Please select a location, operatory, and provider to view the corresponding schedule.
                            </div>
                        </div>
                    )}
                    {isPending && hasData() && (
                        <div className="availability-weekly-view__loading">
                            <div className="availability-weekly-view__no-data-icon">
                                <i className="material-icons">info</i>
                            </div>
                            <div className="availability-weekly-view__no-data-message">Loading...</div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default AvailabilityWeeklyView
