import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Checkbox, CircularProgress, FormControlLabel, Grid } from '@mui/material'
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import classNames from 'classnames'
import iassign from 'immutable-assign'
import _ from 'lodash'
import moment from 'moment'

import { RootState } from '../../../appReducer'
import { CustomFieldType } from '../../../models/enums'
import { useAppDispatch } from '../../../util/useAppDispatch'
import CopyText from '../../shared/CopyText'
import CustomField from '../../shared/custom-fields/CustomField'
import CustomMultiselectField from '../../shared/custom-fields/CustomMultiselectField'
import {
    FormCheckBoxElement,
    FormElement,
    FormFieldElement,
    isEmail,
    isRequired,
    validate,
} from '../../shared/form-validator/validator'
import { textBlue, white } from '../../shared/styles/colors'
import {
    fetchPhoneNumberBlackList,
    resendInvitation,
    resendInvitationV2,
    resetPassword,
    validatePhoneNumber,
} from '../actions'

import permissionListData from './permissionListData'
import StaffConfirmationBox from './StaffConfirmationBox'
import { StaffConfirmationBoxParams, StaffConfirmationBoxType } from './StaffConfirmationBox'
import StaffFormLocationsColumns from './StaffFormLocationsColumns'
import StaffFormNotificationEmailModal from './StaffFormNotificationEmailModal'
import StaffFormNotificationItem from './StaffFormNotificationItem'
import StaffFormNotificationPhoneNumberModal from './StaffFormNotificationPhoneNumberModal'
import StaffFormUnstopSmsNotificationModal from './StaffFormUnstopSmsNotificationModal'
import StaffSuccessBox, { StaffSuccessBoxParams, StaffSuccessBoxType } from './StaffSuccessBox'
import { setCheckedLocation, setCheckedLocationEmailPermission, setCheckedLocationSmsPermission } from './util'

import './StaffForm.sass'

export const StaffFormTheme = createTheme({
    palette: {
        primary: {
            main: textBlue,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    height: '36px',
                },
                contained: {
                    boxShadow: '0',
                    '&:disabled': {
                        backgroundColor: textBlue,
                        color: white,
                        opacity: 0.5,
                    },
                },
                outlined: {
                    borderColor: textBlue,
                },
            },
        },
    },
})

export interface FormElements {
    firstName: FormFieldElement
    lastName: FormFieldElement
    username: FormFieldElement
    permissions: FormCheckBoxElement[]
    role: FormFieldElement
    yextUserId: FormFieldElement
    locations: FormCheckBoxElement[]
    locationsEmailPermissions?: FormCheckBoxElement[]
    locationsSmsPermissions?: FormCheckBoxElement[]
}

type StaffFormState = {
    formElements: FormElements
    isFormValid: boolean
    isFormDirty: boolean
    showFormErrors: boolean
    allLocationsSelected: boolean
    showConfirmationBox: boolean
    confirmationBoxCallbackFn?: () => void
    confirmationBoxType?: StaffConfirmationBoxType
    confirmationBoxTitle?: string
    successBoxType?: StaffSuccessBoxType
    successBoxTitle?: string
    showNotificationEmailModal: boolean
    showNotificationPhoneNumberModal: boolean
    emails: string[]
    phoneNumbers: string[]
    showUnstopSmsNotificationModal: boolean
    phoneNumberToUnstop: string | null
}

type Props = {
    employee?: ModelsV2.Practice.PracticeAccount
    onCreateNewAccount?: (u: ApiV2.Practice.AccountCreate) => void
    onSave?: (u: ApiV2.Practice.AccountUpdate) => void
    onRestore?: () => void
    onClose: () => void
    practiceUserRoles: Models.PracticeUserRole[]
    practice: Models.Practice
}

const StaffForm = (props: Props) => {
    const { practice } = props

    const practiceId = practice.id
    const employeeId = props.employee?.id

    const employee = useSelector((state: RootState) =>
        state.v2.practices.practiceStaff?.[practiceId].list?.find(a => a.id === employeeId),
    )

    const isSavingPracticeStaff = useSelector((state: RootState) => state.practices.isSavingPracticeStaff?.[practiceId])
    const employeeStatus = employee?.account_status?.value || ''

    const [successBox, setSuccessBox] = useState<StaffSuccessBoxType | ''>('')

    const [formData, setFormData] = useState<StaffFormState>({
        formElements: {
            firstName: {
                value: (employee && employee.first_name) || '',
                validators: [isRequired()],
                isValid: false,
                isDirty: false,
            },
            lastName: {
                value: (employee && employee.last_name) || '',
                validators: [isRequired()],
                isValid: false,
                isDirty: false,
            },
            username: {
                value: (employee && employee.username) || '',
                validators: [isEmail()],
                isValid: false,
                isDirty: false,
            },
            permissions: permissionListData.map(p => ({
                checked: Boolean(
                    employee && employee.permission?.find(employeePermission => employeePermission.type === p.type),
                ),
                label: p.display_name,
                value: p.type,
                isValid: true,
                isDirty: true,
            })),
            role: {
                value: (employee && employee.simplifeye_role && employee.simplifeye_role.value) || '',
                validators: [isRequired({ errorMessage: 'Please select user role' })],
                isValid: false,
                isDirty: false,
            },
            yextUserId: {
                value: (employee && employee.yext_user_id) || '',
                validators: [],
                isValid: false,
                isDirty: false,
            },
            locations: practice.locations.map((location, index) => ({
                checked: false,
                label: location.name,
                value: location.id,
                isValid: true,
                isDirty: true,
            })),
            locationsEmailPermissions: practice.locations.map((location, index) => ({
                checked: false,
                label: '',
                value: location.id,
                isValid: true,
                isDirty: true,
            })),
            locationsSmsPermissions: practice.locations.map((location, index) => ({
                checked: false,
                label: '',
                value: location.id,
                isValid: true,
                isDirty: true,
            })),
        },
        emails: (employee && employee.emails) || [],
        phoneNumbers: (employee && employee.phone_numbers) || [],
        isFormValid: false,
        isFormDirty: false,
        showFormErrors: false,
        allLocationsSelected: false,
        showConfirmationBox: false,
        showNotificationEmailModal: false,
        showNotificationPhoneNumberModal: false,
        showUnstopSmsNotificationModal: false,
        phoneNumberToUnstop: null,
        confirmationBoxCallbackFn: undefined,
        confirmationBoxType: undefined,
        confirmationBoxTitle: undefined,
    })
    const [firstLoadDone, setFirstLoadDone] = useState(false)
    const [newEmailList, setNewEmailList] = useState<string[]>([])
    const [emailChangePending, setEmailChangePending] = useState(false)

    const setCheckboxData = useCallback(() => {
        if (!employee) {
            return
        }
        setFormData(
            iassign(formData, (next: StaffFormState) => {
                next.formElements.locations = practice.locations.map((location, index) => ({
                    checked: setCheckedLocation(location, index, employee),
                    label: location.name,
                    value: location.id,
                    isValid: true,
                    isDirty: true,
                }))

                next.formElements.locationsEmailPermissions = practice.locations.map((location, index) => ({
                    checked: setCheckedLocationEmailPermission(location, index, employee),
                    label: '',
                    value: location.id,
                    isValid: true,
                    isDirty: true,
                }))

                next.formElements.locationsSmsPermissions = practice.locations.map((location, index) => ({
                    checked: setCheckedLocationSmsPermission(location, index, employee),
                    label: '',
                    value: location.id,
                    isValid: true,
                    isDirty: true,
                }))

                return next
            }),
        )
    }, [employee, formData, practice.locations])

    const {
        formElements,
        showFormErrors,
        allLocationsSelected,
        showConfirmationBox,
        confirmationBoxCallbackFn,
        confirmationBoxType,
        confirmationBoxTitle,
        showNotificationEmailModal,
        showNotificationPhoneNumberModal,
        emails,
        phoneNumbers,
        showUnstopSmsNotificationModal,
        phoneNumberToUnstop,
    } = formData

    const { permissions, locations, locationsEmailPermissions, locationsSmsPermissions } = formElements

    const sortedLocations: FormCheckBoxElement[] = _.orderBy(locations, [
        (location: FormCheckBoxElement) => (location.label as string).toLowerCase(),
    ])

    const hasFlowProduct = (() => {
        return employee && practice.products.find(product => product.value === 'flow' && product.active === true)
    })()

    const hasYextProduct = !!practice.products.find(p => p.value === 'yext')?.active
    const hasReviewsProduct = !!practice.products.find(p => p.value === 'reviews')?.active
    const hasYextOrReviewsProductActive = hasYextProduct || hasReviewsProduct

    const hasPhoneNumberAdded = (() => {
        return phoneNumbers.length > 0
    })()

    const isOwner = formElements.role.value === 'practice_owner'

    const areLocationsValid = Boolean(sortedLocations.find(l => l.checked)) || isOwner
    const showNoLocationsSelectedError = !areLocationsValid && formElements.role.value

    const dispatch = useAppDispatch()

    const updateSelectAllLocation = useCallback(() => {
        setFormData(
            iassign(formData, (next: StaffFormState) => {
                const allLocationsSelected = !Boolean(next.formElements.locations.find(location => !location.checked))
                next.allLocationsSelected = allLocationsSelected
                return next
            }),
        )
    }, [formData])

    const validateForm = useCallback(async () => {
        await setFormData(
            iassign(formData, (next: StaffFormState) => {
                next.isFormValid = validate(formElements)
                return next
            }),
        )
        updateSelectAllLocation()
    }, [formData, formElements, updateSelectAllLocation])

    const onUpdate = async (modifier: (changes: FormElements) => void) => {
        return setFormData(
            iassign(formData, (next: StaffFormState) => {
                modifier(next.formElements)
                next.isFormDirty = true
                return next
            }),
        )
    }

    const onUpdateCheckBox = (formElementName: string, elm: FormCheckBoxElement, checked: boolean) => {
        if (formElementName === 'locationsSmsPermissions' && checked) {
            const phoneNumberBlackList = employee?.phone_number_black_list
            if (phoneNumberBlackList && phoneNumberBlackList.length > 0) {
                setFormData({
                    ...formData,
                    showUnstopSmsNotificationModal: true,
                    phoneNumberToUnstop: phoneNumberBlackList[0],
                })
                return
            }
        }

        onUpdate(formElements => {
            const location = formElements[formElementName].find((p: FormElement) => p.value === elm.value)
            if (location) {
                location.checked = checked
                location.isDirty = true
            }
        })
    }

    useEffect(() => {
        if (employee && !firstLoadDone) {
            setCheckboxData()
            dispatch(fetchPhoneNumberBlackList(practice.id, employee.id))

            const allLocationsSelected = !Boolean(
                employee.practice_locations &&
                    employee.practice_locations.find(
                        employeePracticeLocation => employeePracticeLocation.status === 0, // 'disabled',
                    ),
            )

            setFormData(
                iassign(formData, (next: StaffFormState) => {
                    next.allLocationsSelected = allLocationsSelected
                    return next
                }),
            )

            validateForm()
            setFirstLoadDone(true)
        }
    }, [formData, employee, practice.locations, firstLoadDone, setCheckboxData, dispatch, practice.id, validateForm])

    useEffect(() => {
        if (showConfirmationBox === false && emailChangePending) {
            setTimeout(() => {
                setFormData({
                    ...formData,
                    isFormDirty: true,
                    emails: newEmailList,
                })
                setEmailChangePending(false)
                setNewEmailList([])
            }, 0)
        }
    }, [emails, formData, newEmailList, showConfirmationBox, emailChangePending, validateForm])
    const createReqPayload = useCallback(
        (formElements: Partial<FormElements>) => {
            const {
                firstName,
                lastName,
                username,
                permissions,
                role,
                yextUserId,
                locations,
                locationsEmailPermissions,
                locationsSmsPermissions,
            } = _.transform(
                formElements,
                function(result: Partial<FormElements>, value: FormFieldElement | FormCheckBoxElement, key: any) {
                    result[key] = value
                },
                {},
            ) as Partial<FormElements>

            const requestPayload = {
                firstName: firstName ? firstName.value : '',
                lastName: lastName ? lastName.value : '',
                email: username ? username.value : '',
                permissions: permissions ? permissions.filter(p => p.checked).map(item => item.value) : [],
                role: role ? role.value : '',
                locations: locations
                    ? locations.map(item => ({
                          status: item.checked ? 'enabled' : 'disabled',
                          practiceLocationId: item.value,
                          permissions: {
                              emailNotifications: Boolean(
                                  locationsEmailPermissions?.find(p => p.value === item.value && p.checked),
                              ),
                              smsNotifications: Boolean(
                                  locationsSmsPermissions?.find(p => p.value === item.value && p.checked),
                              ),
                          },
                      }))
                    : [],
                emails: formData.emails,
                phoneNumbers: formData.phoneNumbers,
            }

            if (hasYextOrReviewsProductActive) {
                requestPayload['yextUserId'] = yextUserId && yextUserId.value
            }

            return requestPayload
        },
        [formData.emails, formData.phoneNumbers, hasYextOrReviewsProductActive],
    )

    const [doSave, setDoSave] = useState(false)

    useEffect(() => {
        if (doSave) {
            const isFormValid = validate(formElements)

            if (isFormValid && areLocationsValid) {
                const changes = createReqPayload(formData.formElements)

                if (employeeId) {
                    props.onSave && props.onSave({ ...changes, accountStatus: employeeStatus })
                } else {
                    props.onCreateNewAccount && props.onCreateNewAccount({ ...changes, practiceId: practice.id })
                }
            }
            setDoSave(false)
            validateForm()
        }
    }, [
        createReqPayload,
        doSave,
        formData,
        formData.isFormValid,
        formElements,
        props,
        employeeId,
        employeeStatus,
        practice.id,
        validateForm,
        areLocationsValid,
    ])

    const onSave = async () => {
        await validateForm()
        await setFormData({
            ...formData,
            showFormErrors: true,
        })
        setDoSave(true)
    }

    const onDeleteAccount = () => {
        const callback = () => {
            const changes = createReqPayload(formElements)
            changes['accountStatus'] = 'deleted'
            props.onSave && props.onSave(changes)
        }

        onShowConfirmationBox({ type: 'deleteAccount', callback })
    }

    const onDeactivateAccount = () => {
        const callback = () => {
            const changes = createReqPayload(formElements)
            changes['accountStatus'] = 'deactivated'
            props.onSave && props.onSave(changes)
        }

        onShowConfirmationBox({ type: 'deactivateAccount', callback })
    }

    const onReactivateAccount = () => {
        const callback = () => {
            const changes = createReqPayload(formElements)
            changes['accountStatus'] = 'active'
            props.onSave && props.onSave(changes)
        }

        onShowConfirmationBox({ type: 'reactivateAccount', callback })
    }

    const onRestoreAccount = () => {
        const callback = () => {
            if (props.onRestore) {
                props.onRestore()
            }
        }

        onShowConfirmationBox({ type: 'restoreAccount', callback })
    }

    const onInviteResend = () => {
        const callback = () => {
            if (employee) {
                const changes: Api.InviteResend = {
                    username: employee.username,
                    practice_id: practice.id,
                }

                const changesV2: ApiV2.Practice.InviteResend = {
                    email: employee.username,
                    practiceId: practice.id,
                }

                dispatch(employee?.is_sso ? resendInvitationV2(changesV2) : resendInvitation(changes))
            }
            onCloseConfirmationBox()
        }
        onShowConfirmationBox({ type: 'resendInvitation', callback })
    }

    const onResetPassword = () => {
        const callback = async () => {
            let success = false
            if (employee) {
                const changes: ApiV2.Practice.ResetPassword = {
                    email: employee.username,
                }

                const resetResponse = await dispatch(resetPassword(changes))

                if (resetResponse.success) {
                    success = true
                }
            }
            onCloseConfirmationBox()
            if (success) {
                onShowSuccessBox({ type: 'resetPassword' })
            }
        }
        onShowConfirmationBox({ type: 'resetPassword', callback })
    }

    const onClose = () => {
        props.onClose()
    }

    const onCloseConfirmationBox = () => {
        setFormData(
            iassign(formData, (next: StaffFormState) => {
                next.showConfirmationBox = false
                next.confirmationBoxCallbackFn = undefined
                next.confirmationBoxType = undefined

                return next
            }),
        )
    }

    const onShowConfirmationBox = ({ type, callback, title }: StaffConfirmationBoxParams) => {
        setFormData(
            iassign(formData, (next: StaffFormState) => {
                next.showConfirmationBox = true
                next.confirmationBoxType = type
                next.confirmationBoxCallbackFn = callback
                next.confirmationBoxTitle = title
                return next
            }),
        )
    }

    const onCloseSuccessBox = () => {
        setSuccessBox('')
    }

    const onShowSuccessBox = ({ type }: StaffSuccessBoxParams) => {
        setSuccessBox(type)
    }

    const onSelectAllLocations = (checked: boolean) => {
        setFormData(
            iassign(formData, (next: StaffFormState) => {
                next.isFormDirty = true
                next.allLocationsSelected = checked
                next.formElements.locations.forEach(l => (l.checked = checked))
                if (next.formElements.locationsEmailPermissions) {
                    next.formElements.locationsEmailPermissions.forEach(l => (l.checked = checked))
                }
                if (next.formElements.locationsSmsPermissions) {
                    next.formElements.locationsSmsPermissions.forEach(l => (l.checked = checked))
                }
                return next
            }),
        )
    }

    const hasStatus = (status: Models.AccountStatusValue) => {
        return employee && employee.account_status?.value === status
    }

    const onShowNotificationEmailModal = () => {
        setFormData({ ...formData, showNotificationEmailModal: true })
    }

    const onShowNotificationPhoneNumberModal = () => {
        if (!hasPhoneNumberAdded) {
            setFormData({ ...formData, showNotificationPhoneNumberModal: true })
        }
    }

    const onCloseNotificationEmailModal = () => {
        setFormData({ ...formData, showNotificationEmailModal: false })
    }

    const onCloseNotificationPhoneNumberModal = () => {
        setFormData({ ...formData, showNotificationPhoneNumberModal: false })
    }

    const onCloseUnstopSmsNotificationModal = () => {
        setFormData({ ...formData, showUnstopSmsNotificationModal: false, phoneNumberToUnstop: null })
    }

    const onSaveEmail = (newEmail: string, oldEmail?: string) => {
        let updatedEmailList = oldEmail ? formData.emails.filter(e => e !== oldEmail) : [...formData.emails]
        updatedEmailList.push(newEmail)
        updatedEmailList = _.uniq(updatedEmailList)
        setFormData(
            iassign(formData, (next: StaffFormState) => {
                next.emails = updatedEmailList
                next.isFormDirty = true
                return next
            }),
        )
        setNewEmailList(updatedEmailList)
        setEmailChangePending(true)
    }

    const onRemoveEmail = (emailToRemove: string) => {
        const callback = async () => {
            const newEmails = formData.emails.filter(email => email !== emailToRemove)
            await setFormData(
                iassign(formData, (next: StaffFormState) => {
                    next.isFormDirty = true
                    next.emails = newEmails
                    return next
                }),
            )
            setEmailChangePending(true)
            setNewEmailList(newEmails)
            onCloseConfirmationBox()
        }
        onShowConfirmationBox({
            type: 'deleteEmail',
            callback: callback,
            title: `Are you sure you want to delete the email ${emailToRemove}?`,
        })
    }

    const onSavePhoneNumber = (newPhoneNumber: string) => {
        setFormData(
            iassign(formData, (next: StaffFormState) => {
                next.phoneNumbers.push(newPhoneNumber)
                next.phoneNumbers = _.uniq(next.phoneNumbers)
                next.isFormDirty = true
                return next
            }),
        )
    }

    const onRemovePhoneNumber = (phoneNumberToRemove: string) => {
        setFormData(
            iassign(formData, (next: StaffFormState) => {
                next.phoneNumbers = next.phoneNumbers.filter(phoneNumber => phoneNumber !== phoneNumberToRemove)
                next.formElements.locationsSmsPermissions &&
                    next.formElements.locations.length &&
                    next.formElements.locations.forEach(location => {
                        onUpdateCheckBox('locationsSmsPermissions', location, false)
                    })
                next.isFormDirty = true
                return next
            }),
        )
    }

    const onValidatePhoneNumber = async (phoneNumber: string) => {
        const { isValid, message } = await dispatch(validatePhoneNumber(phoneNumber))
        return { isValid, message }
    }

    const confirmationModalOpened =
        showConfirmationBox ||
        successBox ||
        showNotificationEmailModal ||
        showNotificationPhoneNumberModal ||
        showUnstopSmsNotificationModal

    const confirmationModalOpenedClassName =
        showConfirmationBox || successBox ? ' staff-member-confirmation-modal' : ' staff-member-confirmation-modal-wide'

    const allMandatoryFieldsPopulated =
        formData.formElements.firstName.value &&
        formData.formElements.lastName.value &&
        formData.formElements.username.value &&
        formData.formElements.role.value

    const isSSO = Boolean(employee?.is_sso)

    const noDeleteOption = isSSO || hasStatus('deleted')
    const showDeactivate = isSSO ? hasStatus('active') || hasStatus('invited') : hasStatus('active')
    const showReactivate = hasStatus('deactivated')

    return (
        <div
            className={`${confirmationModalOpened ? confirmationModalOpenedClassName : `contents staff-member-modal`}`}
        >
            <div className="close-staff-form-modal-button-container">
                <div className="close-staff-form-modal-button" onClick={onClose}>
                    <i className="material-icons">close</i>
                </div>
            </div>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={StaffFormTheme}>
                    <div className={classNames('staff-member-form')}>
                        {showConfirmationBox && (
                            <StaffConfirmationBox
                                employee={employee}
                                confirmFn={confirmationBoxCallbackFn}
                                closeFn={onCloseConfirmationBox}
                                type={confirmationBoxType}
                                title={confirmationBoxTitle}
                            />
                        )}
                        {successBox && (
                            <StaffSuccessBox employee={employee} closeFn={onCloseSuccessBox} type={successBox} />
                        )}
                        {showNotificationEmailModal && (
                            <StaffFormNotificationEmailModal
                                onClose={onCloseNotificationEmailModal}
                                onSaveEmail={onSaveEmail}
                                onRemoveEmail={onRemoveEmail}
                            />
                        )}
                        {showNotificationPhoneNumberModal && (
                            <StaffFormNotificationPhoneNumberModal
                                onClose={onCloseNotificationPhoneNumberModal}
                                onSavePhoneNumber={onSavePhoneNumber}
                                validatePhoneNumber={onValidatePhoneNumber}
                            />
                        )}
                        {showUnstopSmsNotificationModal && (
                            <StaffFormUnstopSmsNotificationModal
                                onClose={onCloseUnstopSmsNotificationModal}
                                phoneNumberToUnstop={phoneNumberToUnstop}
                            />
                        )}
                        <div className="form">
                            <Grid container={true} spacing={0}>
                                {!showConfirmationBox && !successBox && (
                                    <Grid item={true} xs={8} className="top-left-container">
                                        {employee && (
                                            <div>
                                                <div className="account-id">
                                                    <CopyText text={employee.id}>Account ID: {employee.id}</CopyText>
                                                </div>
                                                <div className="created">
                                                    Registered on: {moment(employee.created).format('MM/DD/YYYY')}
                                                </div>
                                            </div>
                                        )}
                                        <div className="title">
                                            {employee
                                                ? `${employee.first_name} ${employee.last_name}`
                                                : 'New User Information'}
                                        </div>
                                        {!hasStatus('deleted') && (
                                            <div>Please complete the following user information:</div>
                                        )}
                                    </Grid>
                                )}
                                {employee && !showConfirmationBox && !successBox && (
                                    <Grid item={true} xs={4} className="top-action-btn-container">
                                        <Grid container={true} spacing={0} justifyContent="flex-end">
                                            {hasStatus('invited') && (
                                                <Grid item={true}>
                                                    <div className="top-action-btn" onClick={onInviteResend}>
                                                        <i className="material-icons">email</i>
                                                        <div className="top-action-btn-name">Resend Invite</div>
                                                    </div>
                                                </Grid>
                                            )}
                                            {!hasStatus('invited') &&
                                                !hasStatus('deleted') &&
                                                !hasStatus('deactivated') && (
                                                    <Grid item={true}>
                                                        <div className="top-action-btn" onClick={onResetPassword}>
                                                            <i className="material-icons">refresh</i>
                                                            <div className="top-action-btn-name">Reset Password</div>
                                                        </div>
                                                    </Grid>
                                                )}
                                            {!noDeleteOption && (
                                                <Grid item={true}>
                                                    <div className="top-action-btn" onClick={onDeleteAccount}>
                                                        <i className="material-icons icon-trash">delete</i>
                                                        <div className="top-action-btn-name">Delete User</div>
                                                    </div>
                                                </Grid>
                                            )}
                                            {showDeactivate && (
                                                <Grid item={true}>
                                                    <div className="top-action-btn" onClick={onDeactivateAccount}>
                                                        <i className="material-icons icon-trash">flash_off</i>
                                                        <div className="top-action-btn-name">Deactivate User</div>
                                                    </div>
                                                </Grid>
                                            )}
                                            {showReactivate && (
                                                <Grid item={true}>
                                                    <div className="top-action-btn" onClick={onReactivateAccount}>
                                                        <i className="material-icons icon-trash">flash_on</i>
                                                        <div className="top-action-btn-name">Reactivate User</div>
                                                    </div>
                                                </Grid>
                                            )}
                                            {hasStatus('deleted') && (
                                                <Grid item={true}>
                                                    <div className="top-action-btn" onClick={onRestoreAccount}>
                                                        <i className="material-icons">add_circle</i>
                                                        <div className="top-action-btn-name top-action-btn-name--right">
                                                            Restore Account
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                            {hasStatus('deleted') && !showConfirmationBox && !successBox && (
                                <div className="account-deleted-info-box">
                                    <div className="text-container">
                                        <i className="material-icons">remove_circle</i>
                                        <div className="text">
                                            <b>
                                                User {`${employee?.first_name} ${employee?.last_name}`} has been
                                                deleted.{' '}
                                            </b>
                                            This user account will be permanently deleted in{' '}
                                            {employee && employee.days_until_deletion} days.
                                        </div>
                                    </div>
                                    <div className="btns-container">
                                        <Button color="primary" variant="contained" onClick={onClose}>
                                            View User Dashboard
                                        </Button>
                                    </div>
                                </div>
                            )}

                            {!showConfirmationBox && !successBox && (
                                <div className="form-overlay-container">
                                    {hasStatus('deleted') && <div className="overlay" />}
                                    <Grid container={true} wrap="wrap" spacing={3}>
                                        <Grid item={true} md={3} sm={6}>
                                            <CustomField
                                                required={true}
                                                error={showFormErrors && formElements.firstName.firstErrorMessage}
                                                errorMessage={formElements.firstName.firstErrorMessage}
                                                value={formElements.firstName.value}
                                                customFieldType={CustomFieldType.INPUT}
                                                label="First Name*"
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                    onUpdate(({ firstName: u }) => {
                                                        u.value = event.target.value
                                                        u.isDirty = true
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item={true} md={3} sm={6}>
                                            <CustomField
                                                required={true}
                                                error={showFormErrors && formElements.lastName.firstErrorMessage}
                                                errorMessage={formElements.lastName.firstErrorMessage}
                                                value={formElements.lastName.value}
                                                customFieldType={CustomFieldType.INPUT}
                                                label="Last Name*"
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                    onUpdate(({ lastName: u }) => {
                                                        u.value = event.target.value
                                                        u.isDirty = true
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item={true} lg={4} md={3} sm={6}>
                                            <CustomField
                                                required={true}
                                                error={showFormErrors && formElements.username.firstErrorMessage}
                                                errorMessage={formElements.username.firstErrorMessage}
                                                value={formElements.username.value}
                                                customFieldType={CustomFieldType.INPUT}
                                                label="Email Address (Username)*"
                                                disabled={Boolean(employeeId && employee?.is_sso)}
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                    onUpdate(({ username: u }) => {
                                                        u.value = event.target.value.trim()
                                                        u.isDirty = true
                                                    })
                                                }
                                            />
                                        </Grid>
                                        <Grid item={true} lg={2} md={3} sm={6}>
                                            <div className="role-picker">
                                                <CustomMultiselectField
                                                    error={showFormErrors && formElements.role.firstErrorMessage}
                                                    errorMessage={formElements.role.firstErrorMessage}
                                                    items={props.practiceUserRoles}
                                                    maxSelected={1}
                                                    selectedItems={[formElements.role.value]}
                                                    keyProperty="value"
                                                    displayProperty="display"
                                                    placeholder=""
                                                    label="Role*"
                                                    onSelectElement={(values: string[]) =>
                                                        onUpdate(({ role: u }) => {
                                                            u.value = values[0]
                                                            u.isDirty = true
                                                        })
                                                    }
                                                />
                                            </div>

                                            {/* <CustomField
                                                required={true}
                                                error={showFormErrors && formElements.role.firstErrorMessage}
                                                errorMessage={formElements.role.firstErrorMessage}
                                                value={formElements.role.value}
                                                customFieldType={CustomFieldType.SELECT}
                                                label="Role*"
                                                placeholder="Role select"
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                    onUpdate(({ role: u }) => {
                                                        u.value = event.target.value
                                                        u.isDirty = true
                                                    })
                                                }
                                            >
                                                <option value="" />
                                                {listOfUserRoles()}
                                            </CustomField> */}
                                        </Grid>
                                        {hasYextOrReviewsProductActive && (
                                            <Grid item={true} md={3}>
                                                <CustomField
                                                    required={true}
                                                    error={showFormErrors && formElements.yextUserId.firstErrorMessage}
                                                    errorMessage={formElements.yextUserId.firstErrorMessage}
                                                    value={formElements.yextUserId.value}
                                                    customFieldType={CustomFieldType.INPUT}
                                                    label="Yext User ID"
                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                        onUpdate(({ yextUserId: u }) => {
                                                            u.value = event.target.value.trim()
                                                            u.isDirty = true
                                                        })
                                                    }
                                                />
                                            </Grid>
                                        )}
                                    </Grid>

                                    <Grid container={true} spacing={0}>
                                        <Grid item={true} xs={12}>
                                            <div className="title">Notifications</div>
                                            <div>
                                                You can add additional email address(es) and mobile phone number to
                                                receive notifications via email and SMS.
                                            </div>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} className="notifications-column">
                                            <div className="notifications-subtitle">
                                                <div className="subtitle">Additional Email(s)</div>
                                                <div className="btn" onClick={onShowNotificationEmailModal}>
                                                    <i className="material-icons">email</i>
                                                    <div className="label">Add another email</div>
                                                </div>
                                            </div>
                                            <div className="notifications">
                                                {emails.map((email, index) => (
                                                    <StaffFormNotificationItem
                                                        key={index}
                                                        label={email}
                                                        type="email"
                                                        onShowConfirmationBox={onShowConfirmationBox}
                                                        onCloseConfirmationBox={onCloseConfirmationBox}
                                                        onSaveEmail={onSaveEmail}
                                                        onRemoveEmail={onRemoveEmail}
                                                        validatePhoneNumber={onValidatePhoneNumber}
                                                    />
                                                ))}
                                            </div>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} className="notifications-column">
                                            <div className="notifications-subtitle">
                                                <div className="subtitle">Mobile Phone #</div>
                                                <div
                                                    className={classNames(`btn`, {
                                                        [`btn-disabled`]: hasPhoneNumberAdded,
                                                    })}
                                                    onClick={onShowNotificationPhoneNumberModal}
                                                >
                                                    <i className="material-icons">phone</i>
                                                    <div className="label">Add mobile phone</div>
                                                </div>
                                            </div>
                                            <div className="notifications">
                                                {phoneNumbers.map((phoneNumber, index) => (
                                                    <StaffFormNotificationItem
                                                        key={index}
                                                        label={phoneNumber}
                                                        type="phoneNumber"
                                                        onShowConfirmationBox={onShowConfirmationBox}
                                                        onCloseConfirmationBox={onCloseConfirmationBox}
                                                        onRemovePhoneNumber={onRemovePhoneNumber}
                                                        validatePhoneNumber={onValidatePhoneNumber}
                                                    />
                                                ))}
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid container={true} spacing={0}>
                                        <Grid item={true} xs={12}>
                                            <div className="title">
                                                Practice Location(s)
                                                <div className={'select-all-chk'}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(_1, checked) =>
                                                                    onSelectAllLocations(checked)
                                                                }
                                                                checked={allLocationsSelected}
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Select All"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                Please select which practice location(s) you would like this user to
                                                have access to, including chat metrics and requests.
                                            </div>
                                            {showNoLocationsSelectedError && (
                                                <div className="error-text-wrapper">
                                                    <i className="material-icons">error</i>
                                                    <span className="error-text">
                                                        Please select at least one practice location.
                                                    </span>
                                                </div>
                                            )}
                                        </Grid>
                                        <StaffFormLocationsColumns
                                            locations={sortedLocations}
                                            role={formElements.role.value}
                                            locationsEmailPermissions={locationsEmailPermissions}
                                            locationsSmsPermissions={locationsSmsPermissions}
                                            onUpdateCheckBox={onUpdateCheckBox}
                                            phoneNumbers={phoneNumbers}
                                        />
                                    </Grid>

                                    {hasFlowProduct && (
                                        <Grid container={true} className="permissions-container">
                                            <Grid item={true} xs={12}>
                                                <div className="title">Permissions</div>
                                            </Grid>
                                            <Grid container={true} wrap="wrap">
                                                {permissions.map(permission => (
                                                    <Grid
                                                        item={true}
                                                        key={permission.value}
                                                        className="permission-checkbox"
                                                        xs={true}
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    onChange={(_1, checked) =>
                                                                        onUpdateCheckBox(
                                                                            'permissions',
                                                                            permission,
                                                                            checked,
                                                                        )
                                                                    }
                                                                    checked={permission.checked}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={permission.label}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid
                                        container={true}
                                        spacing={2}
                                        className="buttons-container"
                                        justifyContent="center"
                                    >
                                        <Grid item={true}>
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                onClick={onClose}
                                                className="primary"
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item={true}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={onSave}
                                                disabled={
                                                    !formData.isFormDirty ||
                                                    Boolean(isSavingPracticeStaff) ||
                                                    !areLocationsValid ||
                                                    (!employee && !allMandatoryFieldsPopulated)
                                                }
                                            >
                                                {employee ? 'Update Account' : 'Create New User'}
                                            </Button>
                                        </Grid>
                                        {Boolean(isSavingPracticeStaff) && (
                                            <div className="staff-circular-progress-loader">
                                                <CircularProgress size={70} color="primary" variant="indeterminate" />
                                            </div>
                                        )}
                                    </Grid>
                                </div>
                            )}
                        </div>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    )
}

export default StaffForm
