import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'

import { FormCheckBoxElement } from '../../shared/form-validator/validator'

import './StaffFormLocationsColumns.sass'

interface StaffFormLocationsColumnsProps {
    role: string
    locations: FormCheckBoxElement[]
    locationsEmailPermissions?: FormCheckBoxElement[]
    locationsSmsPermissions?: FormCheckBoxElement[]
    onUpdateCheckBox: (formElementName: string, elm: FormCheckBoxElement, checked: boolean) => void
    phoneNumbers: string[]
}

type Props = StaffFormLocationsColumnsProps

const StaffFormLocationsColumns = (props: Props) => {
    const {
        role,
        locations,
        locationsEmailPermissions,
        locationsSmsPermissions,
        onUpdateCheckBox,
        phoneNumbers,
    } = props
    const isOwner = role === 'practice_owner'

    return (
        <Grid container={true} className="staff-form-locations-container" wrap="wrap">
            {locations.map((location, index) => (
                <Grid
                    key={location.value + index}
                    item={true}
                    className={index % 2 ? 'locations-column-right' : 'locations-column-left'}
                    xs={12}
                    sm={12}
                    md={6}
                >
                    {(index === 0 || index === 1) && (
                        <Grid
                            container={true}
                            justifyContent="space-between"
                            alignItems="flex-end"
                            className={'locations-column-title ' + (index === 1 ? 'right' : '')}
                        >
                            <Grid item={true}>Location Name</Grid>

                            <Grid item={true} className="permissions-title-container">
                                <div className="permissions-title">Email Notifications</div>
                                <div className="permissions-title">Text Notifications</div>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container={true} justifyContent="space-between" className="locations-row" wrap="wrap">
                        <Grid item={true} xs={8}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={(_1, checked) => onUpdateCheckBox('locations', location, checked)}
                                        checked={location.checked || isOwner}
                                        disabled={isOwner}
                                        color="primary"
                                    />
                                }
                                label={location.label}
                            />
                        </Grid>

                        <Grid item={true} className="permissions-checkbox-container" xs={4}>
                            <div className="permissions-checkbox">
                                {locationsEmailPermissions && (
                                    <Checkbox
                                        onChange={(_1, checked) =>
                                            onUpdateCheckBox('locationsEmailPermissions', location, checked)
                                        }
                                        checked={locationsEmailPermissions.some(
                                            el => el.value === location.value && el.checked,
                                        )}
                                        disabled={!location.checked && !isOwner}
                                        color="primary"
                                    />
                                )}
                            </div>
                            <div className="permissions-checkbox">
                                {locationsSmsPermissions && (
                                    <Checkbox
                                        onChange={(_1, checked) =>
                                            onUpdateCheckBox('locationsSmsPermissions', location, checked)
                                        }
                                        checked={locationsSmsPermissions.some(
                                            el => el.value === location.value && el.checked,
                                        )}
                                        disabled={(!location.checked && !isOwner) || !phoneNumbers.length}
                                        color="primary"
                                    />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}

export default StaffFormLocationsColumns
