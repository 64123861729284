// @ts-nocheck
import SessionManager from '../SessionManager'

import AdminService from './services/AdminService'
import AmplifyService from './services/AmplifyService'
import ConnectService from './services/ConnectService'
import DataService from './services/DataService'
import FeatureFlagsService from './services/FeatureFlagsService'
import IntegrationsService from './services/IntegrationsService'
import PracticeService from './services/PracticeService'
import ReferralsService from './services/ReferralsService'
import SelfSchedulingService from './services/SelfSchedulingService'
import SurveyService from './services/SurveyService'
import YextService from './services/YextService'

class ApiV2Manager {
    Admin: AdminService
    Amplify: AmplifyService
    Survey: SurveyService
    Integrations: IntegrationsService
    Practice: PracticeService
    Referrals: ReferralsService
    Yext: YextService
    SelfScheduling: SelfSchedulingService
    Connect: ConnectService
    Data: DataService
    FeatureFlags: FeatureFlagsService

    constructor() {
        this.token = SessionManager.session
    }

    set token(token: string | undefined) {
        if (token) {
            this.Admin = new AdminService(token)
            this.Amplify = new AmplifyService(token)
            this.Survey = new SurveyService(token)
            this.Integrations = new IntegrationsService(token)
            this.Practice = new PracticeService(token)
            this.Referrals = new ReferralsService(token)
            this.Yext = new YextService(token)
            this.SelfScheduling = new SelfSchedulingService(token)
            this.Connect = new ConnectService(token)
            this.Data = new DataService(token)
            this.FeatureFlags = new FeatureFlagsService(token)
        } else {
            delete this.Admin
            delete this.Amplify
            delete this.Survey
            delete this.Integrations
            delete this.Practice
            delete this.Referrals
            delete this.Yext
            delete this.SelfScheduling
            delete this.Connect
            delete this.Data
            delete this.FeatureFlags
        }
    }

    get headers() {
        return new Headers({
            'content-type': 'application/json',
        })
    }
}

const ApiV2 = new ApiV2Manager()

export default ApiV2
